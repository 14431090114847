import store from "@songfinch/customer/store";
import useStripe, {loadStripe} from "@songfinch/customer/composables/useStripe";
import modal from "@songfinch/shared/plugins/modal/index";

const Cart = () => import("@songfinch/customer/views/checkout/Cart");
const Checkout = () => import("@songfinch/customer/views/checkout/Checkout");
const CompleteCheckout = () => import("@songfinch/customer/views/checkout/CompleteCheckout");
const NonStoryConfirmation = () => import("@songfinch/customer/views/checkout/NonStoryConfirmation");

const checkoutGuard = async () => {
    await loadStripe();
    const {stripe} = useStripe();
    if (!stripe) {
        modal.swal({
            icon: "warning",
            html: `Unable to load the payment system. If the error persists, please try a different browser or 
                       <a class="color-red" href="https://support.songfinch.com/">contact us</a>.`,
        });
        return {name: "Cart"};
    }
    if (!store.state.cart.cart.length()) {
        return {name: "Cart"};
    }
    if (!await store.state.cart.cart.frontendValidation()) {
        return false;
    }
};

export default [
    {
        path: "/cart",
        name: "Cart",
        component: Cart,
        beforeEnter() {
            loadStripe().then();
        },
        meta: {
            skipAuthRedirect: true,
            hideTopBar: true,
            hideFooter: true,
            hideStartButton: true
        },
    },
    {
        path: "/checkout",
        name: "Checkout",
        meta: {
            skipAuthRedirect: true,
            hideTopBar: true,
            CMSData: "/cart",
        },
        component: Checkout,
        beforeEnter: [checkoutGuard],
    },
    {
        path: "/complete-checkout",
        name: "CompleteCheckout",
        meta: {hideTopBar: true},
        component: CompleteCheckout,
    },
    {
        path: "/thank-you",
        name: "NonStoryConfirmation",
        meta: {hideTopBar: true},
        component: NonStoryConfirmation,
    },
];
