class Event {
    constructor() {
        this.events = {};
    }

    on(eventName, fn, clearOnRoute = true) {
        this.events[eventName] = this.events[eventName] || [];
        fn.clearOnRoute = clearOnRoute;
        this.events[eventName].push(fn);
    }

    off(eventName, fn) {
        if (this.events[eventName]) {
            for (let i = 0; i < this.events[eventName].length; i++) {
                if (this.events[eventName][i] === fn) {
                    this.events[eventName].splice(i, 1);
                    break;
                }
            }
        }
    }

    trigger(eventName, data) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(function (fn) {
                fn(data);
            });
        }
    }

    clearRouteEvents() {
        Object.values(this.events).forEach(event => {
            for (let i = event.length - 1; i > -1; i--) {
                if (event[i].clearOnRoute) event.splice(i, 1);
            }
        });
    }
}

export const eventBus = new Event();

export default {
    install(app) {
        app.config.globalProperties.$bus = eventBus;
    }
};
