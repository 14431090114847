<template>
    <DContainer
        class="d-input-with-submit-wrapper"
        :data-test-id="`${dataTestId}`"
    >
        <DInput
            :id="id"
            v-bind="$attrs"
            v-model="model"
            :data-test-id="`${dataTestId}`"
            variant="with-icon"
            :theme="theme"
            :placeholder="placeholder"
            :has-error="hasError"
            @keydown.enter="
                (e: KeyboardEvent) => {
                    (e?.target as HTMLElement)?.blur();
                    $emit('submit');
                }
            "
        />

        <DButton
            class="d-input-with-submit-button"
            icon="enter_arrow"
            variant="icon"
            :has-error="hasError"
            :theme="theme"
            :data-test-id="`${dataTestId}`"
            @click="$emit('submit')"
        />
    </DContainer>
</template>

<script lang="ts" setup>
    import DContainer from "../atoms/DContainer.vue";
    import DButton from "../atoms/DButton.vue";
    import DInput from "../forms/DInput.vue";

    import type {BaseFormProps} from "@songfinch/types";

    const model = defineModel<string>();
    defineEmits(["submit"]);

    withDefaults(defineProps<BaseFormProps>(), {
        theme: "light",
        dataTestId: "DInputWithSubmit",
    });
</script>

<style lang="scss" scoped>
.d-input-with-submit-wrapper {
  width: 100%;
  position: relative;
}

.d-input-with-submit-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>
