/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    computed,
    reactive,
    readonly,
    type DeepReadonly,
    type Reactive,
    type ComputedRef
} from "vue";
import store from "@songfinch/customer/store";


//##################################
export interface ProgressNavItem {
    id: ComputedRef<string> | string;
    allIds?: string[];
    stepIndex: number;
    isCompleted: ComputedRef<boolean> | boolean;
    isOptional?: ComputedRef<boolean> | boolean;
    isSkipped?: ComputedRef<boolean> | boolean;
}

export type ComputedProgressStats = ComputedRef<{
    total: number,
    valid: boolean
}>

export interface SongBuilderProgress {
    sections: DeepReadonly<Reactive<ProgressNavItem[]>>;
    progressStats: DeepReadonly<ComputedProgressStats>;
}

//##################################
const songData = computed(() => store.state.songBuilder.songData);

const sections = reactive<ProgressNavItem[]>([
    {
        id: "BsOccasionRecipient",
        isCompleted: computed(() => !!(songData.value.recipient && songData.value.relationship)),
        stepIndex: 1,
    },
    {
        id: "BsSongArtist",
        isCompleted: computed(() => songData.value.preferred_artist_switch !== undefined),
        stepIndex: 2,
    },
    {
        id: "BsSongCharacteristics",
        isSkipped: computed(() => songData.value.preferred_artist_switch === undefined),
        isCompleted: computed(() => !!(songData.value.genre && songData.value.gender && songData.value.moods?.length && songData.value.tempo)),
        stepIndex: 2,
    },
    {
        id: computed(() => {
            if (songData.value.selected_recommended_artist || songData.value.pick_for_me || songData.value.selected_artists?.length){
                return "BsArtistConfirmation";
            }
            return songData.value.preferred_artist_switch ? "BsSongArtistSelection" : "BsSongArtistsList";
        }),
        allIds: ["BsArtistConfirmation", "BsSongArtistSelection", "BsSongArtistsList"],
        isCompleted: computed(() => !!(
            songData.value.selected_recommended_artist ||
            songData.value.pick_for_me ||
            songData.value.selected_artists?.length
        )),
        isSkipped: computed(() => {
            if (songData.value.preferred_artist_switch === undefined) {
                return true;
            }
            return !songData.value.preferred_artist_switch && !(songData.value.genre && songData.value.gender);
        }),
        stepIndex: 2,
    },
    {
        id: "BsStoryDetailsCombined",
        isCompleted: computed(() => songData.value.questions?.length === 2 && songData.value.questions.every((q: any) => q && q.answer && q.question)),
        stepIndex: 3,
    }
]);


//Progress Stats
const progressStats: ComputedProgressStats = computed(() => {
    let valid = true;
    const steps = new Set();
    sections.forEach(item => {
        steps.add(item.stepIndex);
        if (!item.isCompleted && !item.isOptional && !item.isSkipped) valid = false;
    });
    return {total: steps.size, valid};
});

export const useSongBuilderProgress = (): SongBuilderProgress => {
    return {
        sections: readonly(sections),
        progressStats: readonly(progressStats)
    };
};
