<template>
    <div>
        <h3 class="@mb-2" v-html="product.title"/>
        <div v-if="$digitalTagTypes.includes(product.product_type)" class="l7 @text-sfc-medium-grey @mb-2">
            Digital delivery
        </div>
        <div class="l1">
            {{$sfPriceInDollars(product.price)}}
        </div>
        <div class="@flex @items-center">
            <span class="p4">or 4 interest-free payments of {{$sfPriceInDollars(product.price / 4)}} with</span>
            <img width="48" style="margin-bottom: 2px;" class="@ml-[3px]" :src="klarnaIcon" alt="klarna">
        </div>
    </div>
</template>

<script>
    import klarnaIcon from "@songfinch/customer/assets/images/klarna.png";
    export default {
        name: "ProductTitle",
        props: {
            product: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                klarnaIcon
            };
        }
    };
</script>
