import ExtraVersePopup from "@songfinch/customer/components/store/ExtraVersePopup.vue";
import ImageUploaderPopup2 from "@songfinch/customer/components/store/ImageUploaderPopup2.vue";
import store from "@songfinch/customer/store";
import router from "@songfinch/customer/router";
import type {BundledProduct, Product} from "@songfinch/customer/types/product";
import axios from "axios";

export function setProductForAdditionalInfo(mainProduct: Product, dontShowDetailModal = false) {
    // eslint no-async-promise-executor: off
    return new Promise(async (resolve) => {
        if (!mainProduct) return resolve(false);

        let component;
        let swalSettings;
        let submitted = false;

        const productsWithBunles: Array<Product | BundledProduct> = [mainProduct];

        mainProduct.bundled_products?.forEach(bp => productsWithBunles.push(bp));

        for (const product: Product | BundledProduct of productsWithBunles) {
            const submittedProduct = await new Promise(resolve2 => {
                if (product.name === "extra-verse" && mainProduct.parent_story_id) {
                    component = ExtraVersePopup;
                } else if (product.extra_info?.has_image_uploader) {
                    component = ImageUploaderPopup2;
                    swalSettings = {width: 1250};
                } else {
                    return resolve2(product);
                }

                store.commit("shared/showSlideModal", {
                    component,
                    props: {
                        product
                    },
                    events: {
                        submit: submittedModalProduct => {
                            submitted = true;
                            resolve2(submittedModalProduct);
                        }
                    },
                    swalSettings: {
                        afterClose: () => {
                            if (product && !dontShowDetailModal && !submitted && router.currentRoute.value.name !== "ProductSingle") {
                                store.commit("store/showProductDetailModal", mainProduct.name);
                            }
                            if (!submitted) resolve2(false);
                        },
                        ...swalSettings
                    }
                });
            });

            if (!submittedProduct) {
                resolve(false);
                break;
            }
            Object.assign(product, submittedProduct);
        }

        resolve(mainProduct);
    });
}

type SingleProductPayload = {productSlug:string, getWithParent?: boolean};

export async function loadSingleProduct({productSlug, getWithParent}: SingleProductPayload) {
    const res = await axios.get("store/single_product/" + productSlug, {params: {get_with_parent: getWithParent}});
    return res.data;
}

export async function loadProductOrDefault(payload: SingleProductPayload, defaultSlug: string) {
    try {
        return await loadSingleProduct(payload);
    } catch (e) {
        if (e.response?.data?.error === "Not Found") {
            payload.productSlug = defaultSlug;
            return  await loadSingleProduct(payload);
        } else {
            throw e;
        }
    }
}

export function isInstantProduct(product: Product) {
    return product.extra_info?.instant_product || product.bundled_products?.find(bp => bp.extra_info?.instant_product);
}

export function isOriginalSongProduct(product: Product) {
    return product.name === "personalized-song" || product.bundled_products?.find(bp => bp?.name === "personalized-song");
}

// TODO: Temp const, need to replace and make all dynamics
export const excludedInstantAddons = [
    "free-song-streaming",
    "extra-verse",
    "song-streaming",
    "bundle-slideshow-video-card",
    "artist-gratuity" // Temp
];
