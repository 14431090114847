<template>
    <svg
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        :class="size"
    >
        <template v-for="(path, i) in paths" :key="i">
            <path v-bind="path"/>
        </template>
    </svg>
</template>

<script setup lang="ts">
    import icons from "../assets/icons";
    import type {IconNameTypes} from "@songfinch/types";
    import {computed} from "vue";

    const props = withDefaults(
        defineProps<{
            name: IconNameTypes | (string & NonNullable<unknown>);
            size?: "small" | "medium" | "large";
        }>(),
        {
            size: "medium",
        },
    );

    const paths = computed(() => {
        if (props.name && typeof props.name === "string") {
            const icon = icons.find((icon) => icon.name === props.name);
            if (icon?.paths) {
                return icon.paths;
            }
        }
        throw new Error(
            `The icon: "${props.name}" is either unregistered or does not exist in the "/assets/icons.ts"`,
        );
    });
</script>

<style scoped lang="scss">
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  fill: none;
  stroke: none;
}

.large {
  height: 36px;
  width: 36px;
}

.medium {
  height: 24px;
  width: 24px;
}

.small {
  height: 16px;
  width: 16px;
}
</style>
