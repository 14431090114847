const Error = () => import("@songfinch/customer/views/pages/Error");

export default [
    {
        path: "/500",
        name: "500",
        component: Error,
    },
    {
        path: "/404",
        name: "404",
        component: Error,
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: {name: "404"},
    }
];
