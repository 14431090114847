import type {ComponentThemes, ShadeThemes} from "@songfinch/types";

export const getComponentShade = (
    theme: ComponentThemes,
    hasError?: boolean,
): ShadeThemes => {
    if (hasError) return "error";
    const darkThemes = ["dark", "shadow", "concrete", "transparent-dark"];
    return darkThemes.includes(theme) ? "dark" : "light";
};
