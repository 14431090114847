import Cookies from "js-cookie";

export const removeExistingAdCookies = (cookieName) => {
    Cookies.remove(cookieName, {path: "/", secure: false});
};

export const checkUrlQueryForAdIds = async (query) => {
    if (!Cookies) return;
    const {gclid} = query;
    const cookieExpirationDays = 7;
    if (gclid) {
        const cookieName = "gclid";
        removeExistingAdCookies(cookieName);
        Cookies.set(cookieName, gclid, {expires: cookieExpirationDays});
    }
};