import {computed} from "vue";
import type {Ref} from "vue";

import {useField} from "vee-validate";

export function useFieldValidator(id: Ref<string | undefined>) {
    const {
        value: model,
        errorMessage,
        meta,
        resetField,
        handleBlur,
    } = useField<string>(id.value as string, {
        syncVModel: true,
    });

    const hasError = computed(() => {
        return !meta.valid && meta.dirty && meta.touched;
    });

    return {
        model,
        hasError,
        errorMessage,
        resetField,
        handleBlur,
    };
}
