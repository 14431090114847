export default  (imgUrl) => {
    if (!imgUrl) {
        return false;
    }
    return new Promise((res, rej) => {
        const image = new Image();
        image.onload = () => res(true);
        image.onerror = () => rej();
        image.src = imgUrl;
    });
};
