import router from "./index";
const Story = () => import("@songfinch/customer/views/stories/Story");
const StoryDeliveredArtistTab = () => import("@songfinch/customer/components/story/StoryDeliveredArtistTab");
const StoryDeliveredSongTab = () => import("@songfinch/customer/components/story/StoryDeliveredSongTab");
const AgECard = () => import("@songfinch/customer/views/pages/AgECard");
const EditOriginalSongBuilder = () => import("@songfinch/customer/views/edit_original_song/EditOriginalSongBuilder");

export default [
    {
        path: "/stories/:slug",
        component: Story,
        meta: {
            disableZenDesk: true,
            CMSData: "storyPage",
            hideTopBar: true,
        },
        beforeEnter: async (to) => {
            const useStoryImport = await import("@songfinch/customer/composables/useStory");
            const {loadCurrentStory} = useStoryImport.default();
            if (!await loadCurrentStory(to.params.slug)) {
                return router.push({name: "404"});
            }
        },
        name: "Story",
        redirect: {name: "StorySong"},
        children: [
            {
                path: "",
                name: "StorySong",
                component: StoryDeliveredSongTab
            },
            {
                path: "artist",
                name: "StoryArtist",
                component: StoryDeliveredArtistTab
            }
        ]
    },
    {
        path: "/stories/:slug/ag-ecard",
        name: "AgECard",
        component: AgECard,
        meta: {
            disableZenDesk: true,
            requireAuth: true,
            CMSData: "agECard",
            animation: "pageSlide",
            hideTopBar: true,
        }
    },
    {
        path: "/stories/:slug/edit-your-song",
        name: "OriginalSongBuilderEdit",
        component: EditOriginalSongBuilder,
        meta: {
            CMSData: "songBuilderPrePurchase",
            animation: "pageSlide",
            hideTopBar: true,
            hideNavgiation: true,
            hideFooter: true,
            hideStartButton: true,
            requireAuth: true,
        }
    }
];
