import {watchOnce} from "@vueuse/core";
import {useIDBKeyval, type UseIDBKeyvalReturn} from "@vueuse/integrations/useIDBKeyval";

type UseSfIDBKeyvalReturn<T> = UseIDBKeyvalReturn<T> & {
    isReady: () => Promise<boolean>;
};

export function useSfIDBKeyval<T>(key: string, initialValue: T): UseSfIDBKeyvalReturn<T> {
    const storage = useIDBKeyval<T>(key, initialValue);

    const isReady = () => new Promise<boolean>(resolve => {
        if (storage.isFinished.value) return resolve(true);
        watchOnce(storage.isFinished, () => resolve(true));
    });

    (storage as UseSfIDBKeyvalReturn<T>).isReady = isReady;

    return storage as UseSfIDBKeyvalReturn<T>;
}
