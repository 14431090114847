const Products = () => import("@songfinch/customer/views/products/Products.vue");
const ProductSingle = () => import("@songfinch/customer/views/products/ProductSingle.vue");

export default [
    {
        path: "/products/:product_id",
        name: "ProductSingle",
        component: ProductSingle,
        meta: {CMSData: "productSingle", skipAuthRedirect: true},
    },
    {
        path: "/store",
        name: "Store",
        component: Products,
    }
];
