import {defineAsyncComponent} from "vue";
import type {FormItemTypes} from "@songfinch/types";

export function useFormItemRender() {
    const formComponents = {
        text: defineAsyncComponent(() => import("../../forms/DInput.vue")),
        email: defineAsyncComponent(() => import("../../forms/DInput.vue")),
        url: defineAsyncComponent(() => import("../../forms/DInput.vue")),
        password: defineAsyncComponent(() => import("../../forms/DInput.vue")),
        phone: defineAsyncComponent(() => import("../../forms/DPhone.vue")),
        textarea: defineAsyncComponent(() => import("../../forms/DTextarea.vue")),
        checkbox: defineAsyncComponent(() => import("../../forms/DCheckbox.vue")),
        select: defineAsyncComponent(() => import("../../forms/DSelect.vue")),
        singleselect: defineAsyncComponent(
            () => import("../../forms/DSingleSelect.vue"),
        ),
        multiselect: defineAsyncComponent(
            () => import("../../forms/DMultiSelect.vue"),
        ),
        button: defineAsyncComponent(() => import("../../forms/DInput.vue")),
        inputWithSubmit: defineAsyncComponent(
            () => import("../../forms/DInputWithSubmit.vue"),
        ),
    };

    const isFormType = (typename: string): typename is FormItemTypes =>
        Object.keys(formComponents).includes(typename);

    const resolveFormItem = (typename: FormItemTypes) => {
        if (isFormType(typename)) {
            return formComponents[typename];
        }
    // Todo log error somewhere if not defined
    };

    return {
        resolveFormItem,
        formComponents,
    };
}
