import {createI18n} from "vue-i18n";
import en from "./en";

export default async () => {
    const locale = window.appSettings.locale;
    const messages = {en};

    if (locale && locale !== "en") {
        const module = await import(`./${locale}/index.js`);
        messages[locale] = module?.default;
    }

    return createI18n({
        legacy: false,
        locale,
        messages,
        fallbackLocale: "en", // Use english if other language lacks of translation
    });
};
