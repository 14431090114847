import {ref} from "vue";
import type {SiteNavigationlogoType} from "@songfinch/types/types";

const logoType = ref<SiteNavigationlogoType>("default");

export function useSetNavigationLogo() {
    const setLogoType = (newLogoType: SiteNavigationlogoType) => {
        logoType.value = newLogoType;
    };

    return {
        logoType,
        setLogoType,
    };
}
