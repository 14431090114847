export const isString = (value: unknown): value is string => {
    return typeof value === "string";
};
export const isStringNumber = (value: unknown): value is string => {
    return isString(value) && /^[0-9]+$/.test(value);
};
export const isNumber = (value: unknown): value is number => {
    return typeof value === "number";
};
export const isBoolean = (value: unknown): value is number => {
    return typeof value === "boolean";
};
export const isArray = (value: unknown): value is unknown[] => {
    return Array.isArray(value);
};
export const isObject = (value: unknown): value is Record<string, unknown> => {
    return typeof value === "object" && value !== null && !Array.isArray(value);
};
