import axios from "axios";
import {apply_query_filters, get_query_filters} from "../helpers/apply_query_filters";

const CancelToken = axios.CancelToken;
let source;

export default {
    namespaced: true,
    state: {
        playlists: null,
        filters: null,
        page: 1,
        endOfPages: false,
        total: 0,
    },
    mutations: {
        nextPage(state) {
            state.page++;
        },
        setEndOfPages(state, val) {
            state.endOfPages = val;
        },
        setPlaylists(state, val) {
            if (val?.playlists) {
                state.playlists = state.playlists.concat(val.playlists);
                setTimeout(() => { //Prevent to trigger it right away
                    state.total = val?.total;
                    state.endOfPages = state.playlists.length === state.total;
                }, 100);
            }
        },
        resetList(state) {
            state.playlists = [];
            state.page = 1;
        },
        initFilters(state) {
            this.commit("playlist/resetList");
            state.filters = {
                kinds: [],
                keyword: "",
                per_page: 9,
                ...get_query_filters()
            };
        }
    },
    actions: {
        async loadPlaylists({commit, state}, payload) {
            this.commit("playlist/setEndOfPages", true);
            if (source) {
                source.cancel();
            }
            source = CancelToken.source();

            apply_query_filters(state.filters);

            let res;
            try {
                res = await axios.get("/playlists", {
                    params: (payload || {page: state.page, ...state.filters}),
                    cancelToken: source.token,
                    cache: {ignoreCache: false},
                });
            } catch (e) {
                if (!axios.isCancel(e)) throw new Error(e.message || "failed to load Playlist");
                return;
            }

            if (!payload) {
                commit("setPlaylists", res.data);
            }
            return res.data;
        }
    }
};
