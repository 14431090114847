<template>
    <DContainer class="d-input-wrapper" :class="`${shade}-shade with-icon`">
        <DContainer class="d-input-label-wrapper" :theme="theme">
            <DText
                class="d-input-label"
                type="label"
                variant="form-label"
                :for="formId"
            >
                {{label}}
            </DText>
        </DContainer>

        <select :id="formId" v-model="model" class="d-input" :name="formId">
            <option v-if="placeholder" value="" disabled>
                {{placeholder}}
            </option>
            <template v-for="option in options" :key="option">
                <option v-if="isString(option)" :value="option">
                    {{option}}
                </option>
                <option v-else :value="option.value">
                    {{option.key}}
                </option>
            </template>
        </select>

        <DIcon v-if="hasError" class="d-input-error-icon" name="alert"/>
        <DIcon class="d-input-dropdown-icon" name="dropdown"/>
    </DContainer>
</template>

<script lang="ts" setup>
    import {computed} from "vue";

    import DText from "../atoms/DText.vue";
    import DContainer from "../atoms/DContainer.vue";
    import DIcon from "../atoms/DIcon.vue";

    import {getComponentShade} from "../utils/theme";
    import {isString} from "@songfinch/utils/src/validators";

    import type {BaseFormProps, KeyValueType} from "@songfinch/types";

    const props = withDefaults(
        defineProps<
            BaseFormProps & {
                options: string[] | KeyValueType[];
            }
        >(),
        {
            theme: "light",
        },
    );

    const shade = computed(() => getComponentShade(props.theme, props.hasError));

    const formId = "form-input-" + props.id;
    const model = defineModel<string>();
</script>
