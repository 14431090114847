<template>
    <div class="artistGratuityFields">
        <div class="l8 @mb-2">
            Amount
        </div>
        <MkSelect v-model="selected" required :options="options" placeholder="Select an amount"/>
        <div v-if="selected === 'custom'" class="form-group @mt-4">
            <input
                v-model="custom"
                v-custom-mask="'$$###############'"
                v-validity="'Please enter amount'"
                class="form-control"
                type="text"
                placeholder="Enter amount"
                pattern="\$[1-9]([0-9]+)?"
                required
            >
        </div>
    </div>
</template>

<script>
    import MkSelect from "@songfinch/customer/components/shared/MkSelect";
    import {validity} from "@songfinch/shared/mixins/validity_directives";
    import customMask from "@songfinch/customer/directives/vue_mask_directive";

    export default {
        name: "ArtistGratuityFields",
        directives: {validity, customMask},
        components: {MkSelect},
        emits: ["update:modelValue"],
        data() {
            return {
                selected: "",
                custom: "",
                options: [
                    {id: "30", text: "Thank you so much ($30)"},
                    {id: "40", text: "What a wow moment ($40)"},
                    {id: "50", text: "I’m speechless ($50)"},
                    {id: "custom", text: "Custom"},
                ]
            };
        },
        computed: {
            watcher() {
                return this.custom + this.selected;
            }
        },
        watch: {
            watcher() {
                const price = (this.selected === "custom") ? this.custom.replace("$", "") : this.selected;
                this.$emit("update:modelValue", price * 100);
            }
        },
        created() {
            this.selected = "40"; // preselect
        }
    };
</script>
