import {ref} from "vue";

import type {NotificationType} from "@songfinch/types";

const notification = ref<NotificationType | null>(null);

export function useNotification() {
    const setNotification = (newNotification: NotificationType) => {
        notification.value = newNotification;

        setTimeout(() => {
            notification.value = null;
        }, 10000);
    };

    const clearNotification = () => {
        notification.value = null;
    };

    return {
        notification,
        setNotification,
        clearNotification,
    };
}
