import store from "@songfinch/customer/store";
import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";
const ReferralsSignup = () => import("@songfinch/customer/views/referrals/ReferralsSignup");

export default [
    {
        path: "/r/:code",
        name: "ReferralsSignup",
        component: ReferralsSignup,
        meta: {CMSData: "/r/:code"},
        beforeEnter: (to, from, next) => {
            if (store.state.auth.user) {
                $toastMsg("You already have an existing account.");
                return next({name: "Dashboard"});
            }
            return next();
        }
    }
];
