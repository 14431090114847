<template>
    <div class="product productDescription cmsContent">
        <div v-if="product.description" class="@mb-8">
            <div class="l1 @mb-2.5">
                Product details
            </div>
            <div class="p3" v-html="product.description"/>
        </div>
        <div v-if="product.shipping_info">
            <div class="l1 @mb-2.5">
                Shipping
            </div>
            <div class="p3" v-html="product.shipping_info"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProductDescription",
        props: {
            product: {
                type: Object,
                default: null
            }
        }
    };
</script>

<style>
    @import "@songfinch/customer/stylesheets/includes/pages/_cms-content.pcss";
</style>
