export type FlipperFeatures = {
    [key: string]: boolean
};

export type FlipperValues = {
    [key: string]: "on" | "off"
};

const flipper: FlipperFeatures = {};
export const flipperValues: FlipperValues = {};

export function initFlipper(features?: FlipperFeatures) {
    features ||= window.appSettings?.features || {};
    if (window.appSettings?.env !== "production") {
        const params = new URLSearchParams(window.location.search);
        for (const key of params.keys()) {
            if (key.indexOf("feature_") === 0) {
                const flag = key.slice("feature_".length);
                const value = params.get(key) !== "false" && params.get(key) !== "0";
                features[flag] = value;
            }
        }
    }

    Object.assign(flipper, features);
    Object.assign(flipperValues, Object.keys(features).map((name) => `${name}_${features[name] ? "on" : "off"}`));
}

initFlipper();
export default flipper;
