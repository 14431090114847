import axios from "axios";
import {indexOf, sortBy} from "lodash-es";
import router from "../router";
import ProductDetails from "../components/store/ProductDetails";

export default {
    namespaced: true,
    state: {
        products: null,
        selectedStory: null
    },
    getters: {
        getAvailableProducts(state, val, rootState) {
            return state.products?.filter(p => {
                if (p.extra_info?.one_per_story) {
                    return !rootState.cart.cart.findProductByName(p.name);
                }
                return true;
            });
        },
    },
    mutations: {
        selectStory(state, val) {
            state.selectedStory = val;
        },
        setProducts(state, val) {
            state.products = val;
        },
        showProductDetailModal(state, payload) { // accept string or settings object
            let settings = {
                props: {},
                swalSettings: {width: 1250},
                component: ProductDetails,
            };
            if (typeof payload === "string") {
                settings.props.productSlug = payload;
            } else if (typeof payload === "object") {
                settings = {...settings, ...payload, component: ProductDetails};
            }
            if (settings.props.class === undefined) settings.props.class = "@pt-10 @px-5 desktop:@p-12 @mx-0";
            return this.commit("shared/showSlideModal", settings);
        }
    },
    actions: {
        async loadProducts({commit}, {productsNames, sortByName, storyId, productsProps, includes, excludes} = {}) {
            commit("setProducts", null);
            let res;
            try {
                res = await axios.get("store/products", {
                    params: {
                        name: productsNames,
                        story_id: storyId,
                        includes,
                        excludes
                    }
                });
            } catch (e) {
                return router.push({name: "404"});
            }
            let result = res.data;
            if (sortByName?.length) {
                result = sortBy(res.data, (p) => {
                    const index = indexOf(sortByName, p.name);
                    return index === -1 ? sortByName.length : index;
                });
            }

            // Move free song streaming to the front.
            const freeSongStreamingIndex = result.findIndex(p => p.name === "free-song-streaming");
            result.unshift(result.splice(freeSongStreamingIndex, 1)[0]);

            if (productsProps) {
                result = result.map(p => ({...p, ...productsProps}));
            }

            commit("setProducts", result);
            return result;
        },
    }
};
