import type {NormalizedNavigationItemBase, User} from "@songfinch/types";

export const getAccountNav = (user?: User) => {
    const menuItems: NormalizedNavigationItemBase[] = [
        {
            title: "My account",
            slug: "/dashboard/profile",
        },
        {
            title: "Orders",
            slug: "/dashboard/order-history",
        },
        {
            title: "Credit balance",
            slug: "/dashboard/credit-balance",
        },
        {
            title: "Referrals",
            slug: "/dashboard/referral-program",
        },
    ];

    if (user?.role === "admin") { 
        menuItems.unshift({
            title: "Admin",
            externalLink: "/admin/stories",
            skipVueRouting: true
        });
    }

    if (user?.role === "artist" && user.id) { 
        menuItems.unshift({
            title: "Artist Dashboard",
            externalLink: "/artist-admin",
            skipVueRouting: true
        });
    }

    return {
        title: "Account",
        subNavItems: menuItems
    };

};