import type {NormalizedCTA, NormalizedAsset} from "@songfinch/types";
import type {Navigation, NormalizedNavigationItem, RawCMSMedia, RawCMSCta, RawCMSFaq} from "@songfinch/types";

export const normalizeCMSMedia = (rawCMSMedia?: RawCMSMedia): NormalizedAsset | undefined => {
    if (!rawCMSMedia) return undefined;
    const normalizedMedia: NormalizedAsset = {
        contentType: rawCMSMedia.asset.resource_type,
        title: rawCMSMedia.title,
        url: rawCMSMedia.asset.url
    };

    return normalizedMedia;
};


export const normalizeCMSCta = (rawCMSCta?: RawCMSCta): NormalizedCTA | undefined  => {
    if (!rawCMSCta) return undefined;
    const normalziedCTA: NormalizedCTA = {
        type: rawCMSCta.type,
        text: rawCMSCta.text,
        variant: rawCMSCta.variant
    };

    if (rawCMSCta.slug && (rawCMSCta.type === "link")) {
        normalziedCTA.link = {
            title: rawCMSCta.text,
            slug: rawCMSCta.slug,
        };
    }
    return normalziedCTA;
};

export const normalizeCMSPageData = (data: unknown) => {
    if (!data) return undefined;
    return data;
};

type RawBasNavigationItem = {
    label: "Learn"
    link: "#"
    external?: ["external"]
    badge?: "new" | "premium" | "undefined"
}

type RawNavigationItem = RawBasNavigationItem & {
    sub_nav?: RawBasNavigationItem[]
}

type RawFooterNavigationItem = RawBasNavigationItem & { 
    title: string;
    links?: RawBasNavigationItem[]
    name?: string;
    external?: ["external"]
}

export const normalizeSiteNavigationItem = (item: RawNavigationItem | RawFooterNavigationItem): NormalizedNavigationItem => {
    const normalizedItem: NormalizedNavigationItem = {
        title: item.label || (item as RawFooterNavigationItem).title,
    };

    if (item.external?.length) {
        normalizedItem.externalLink = item.link;
    } else {
        normalizedItem.slug = item.link;
    }

    if (item.badge && item.badge !== "undefined") {
        normalizedItem.tag = item.badge;
    }

    const subMenu = (item as RawNavigationItem).sub_nav || (item as RawFooterNavigationItem).links || undefined;
    
    if (subMenu) {
        normalizedItem.subNavItems = Object.values(subMenu).map(subItem => normalizeSiteNavigationItem(subItem));
    }
    
    return normalizedItem;
};


export const normalizeSiteNavigation = (data: RawNavigationItem[]): Navigation => {
    if (!data) return [];
    return Object.values(data).map(item => normalizeSiteNavigationItem(item));
};

export const normalizeSiteFooterNavigation = (data: RawFooterNavigationItem[]): Navigation => {
    if (!data) return [];
    return Object.values(data).map(item => normalizeSiteNavigationItem(item));
};

export const normalizeFaqs = (faqs: RawCMSFaq[]) => {
    return faqs.map(
        (faq: {
                question: string;
                answer: string;
            }) => {
            return {
                title: faq.question,
                content: faq.answer
            };
        }
    );
};