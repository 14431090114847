export default function onBrowserInteraction(callback) {
    const functionHelper = () => {
        callback();
        window.removeEventListener("click", functionHelper);
        window.removeEventListener("scroll", functionHelper);
    };

    window.addEventListener("click", functionHelper);
    window.addEventListener("scroll", functionHelper);
}
