import Cookies from "js-cookie";
import analyticsEvent from "@songfinch/shared/helpers/analyticsEvent";

export function setTemporaryEmail(email: string) {
    Cookies.set("sf_temporary_email", email, {expires: 360});
    if (email) {
        analyticsEvent("_sf_identify_user", {email});
    }
}

export function getTemporaryEmail() {
    return Cookies.get("sf_temporary_email");
}
