<template>
    <DLink :class="shade" to="/" aria-label="home">
        <DImg v-if="logoImage" class="branded-logo" :src="logoImage" alt="Songfinch Logo"/>
        <DSongfinchLogo v-else :class="shade"/>
    </DLink>
</template>

<script setup lang="ts">

    import {computed} from "vue";

    import DLink from "./DLink.vue";
    import DImg from "./DImg.vue";
    import DSongfinchLogo from "./DSongfinchLogo.vue";

    import {getComponentShade} from "../utils/theme";

    import type {ComponentThemes, SiteNavigationlogoType} from "@songfinch/types";


    const props = withDefaults(
        defineProps<{
            theme?: ComponentThemes;
            logoType: SiteNavigationlogoType;
        }>(),
        {
            theme: "light",
            logoType: "default"
        },
    );

    

    const logoImage = computed(() => {
        if (props.logoType === "americanGreetings") { 
            return "https://content.songfinch.com/res/songfinch/image/statics/logo_sf_ag.png";
        }
        if (props.logoType === "blueMountain") { 
            return "https://content.songfinch.com/res/songfinch/image/statics/logo_sf_ag.png";
        }
        return undefined;
    });

    const shade = computed(() => getComponentShade(props.theme));
</script>

<style lang="scss" scoped>
.light {
  color: var(--soft-black);
}

.dark {
  color: var(--chalk);
}

.branded-logo {
  width: 100%; 
  height: 100%;
  object-fit: contain;
}
</style>
