import {ref, Ref, shallowRef, readonly} from "vue";
import {TopBannerParams} from "@songfinch/customer/types/top_banner";

export const globalTopBannerPayload: Ref<TopBannerParams | undefined> = ref();

export const setTopBanner = (payload?: TopBannerParams) => {
    if (payload?.component) {
        payload.component = shallowRef(payload.component);
    }
    globalTopBannerPayload.value = payload;
};

export default function useTopBanner() {
    return {
        setTopBanner,
        globalTopBannerPayload: readonly(globalTopBannerPayload)
    };
}
