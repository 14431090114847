import type {StaticOptionsIdType, FormOptionsType} from "@songfinch/types";

type StaticOptionsObject = {
  [k: string]: FormOptionsType[];
};

const staticOptions: StaticOptionsObject = {
    pickForMe: [
        {
            key: "Help me find the perfect artist",
            value: true,
        },
        {
            key: "I know who I want",
            value: false,
        },
    ],
    relationship: [
        "Wife",
        "Husband",
        "Partner",
        "Boyfriend",
        "Girlfriend",
        "Fiancé/Fiancée",
        "Significant Other",
        "Mom",
        "Dad",
        "Parents",
        "Daughter",
        "Son",
        "Children",
        "Grandmother",
        "Grandfather",
        "Grandparents",
        "Family",
        "Friend",
        "Other",
    ],
    occasion: [
        "Anniversary",
        "Birthday",
        "Christmas",
        "Engagement",
        "Father's Day",
        "Graduation",
        "Just Because",
        "Memorial",
        "Mother's Day",
        "New Baby",
        "Proposal",
        "Valentine's Day",
        "Wedding",
        "Other",
    ],
    promptChoice: [
        "Your relationship story",
        "Favorite memories together",
        "Favorite things about them",
        "What they mean to you",
        "Favorite things to do together",
        "How they’ve shaped you",
        "Inside jokes and funny stories",
        "Advice you have for them",
        "Other stories or memories",
    ],
    genre: [
        {
            key: "Rap / Hip-Hop",
            value: "3",
        },
        {
            key: "Folk",
            value: "4",
        },
        {
            key: "Rock",
            value: "8",
        },
        {
            key: "Christian",
            value: "9",
        },
        {
            key: "R\u0026B",
            value: "6",
        },
        {
            key: "Country",
            value: "1",
        },
        {
            key: "Singer Songwriter",
            value: "2",
        },
        {
            key: "Acoustic Pop",
            value: "5",
        },
        {
            key: "Spanish",
            value: "10",
        },
    ],
    gender: [
        {
            key: "Female",
            value: "2",
        },
        {
            key: "Male",
            value: "1",
        },
        {
            key: "No Preference",
            value: "3",
        },
    ],
    moods: [
        {
            key: "happy",
            value: "4",
        },
        {
            key: "lighthearted",
            value: "5",
        },
        {
            key: "comical",
            value: "2",
        },
        {
            key: "heartfelt",
            value: "43",
        },
        {
            key: "uplifting",
            value: "9",
        },

        {
            key: "romantic",
            value: "7",
        },
        {
            key: "reflective",
            value: "6",
        },
        {
            key: "somber",
            value: "8",
        },
    ],
    tempo: [
        {
            key: "Slow",
            value: "2",
        },
        {
            key: "Medium",
            value: "4",
        },
        {
            key: "Up-tempo",
            value: "3",
        },
    ],
};

export const getFormInputStaticOptions = (
    staticOptionsId: StaticOptionsIdType,
): FormOptionsType[] => {
    return staticOptions[staticOptionsId];
};
