<script lang="ts" setup>
    import DContainer from "../atoms/DContainer.vue";
    import DIcon from "../atoms/DIcon.vue";
    import DLink from "../atoms/DLink.vue";
    import DText from "../atoms/DText.vue";

    import DFromItem from "../forms/DFormItem.vue";

    import type {Navigation, FormConfigurationInputType} from "@songfinch/types";

    defineEmits(["subscribeToMailingList"]);

    withDefaults(
        defineProps<{
            dataTestId?: string;
            navigation: Navigation;
            navigationSocial: Navigation;
            newletterInput: FormConfigurationInputType;
        }>(),
        {
            dataTestId: "DSiteFooter",
        },
    );

    const currentYear = new Date().getFullYear();
</script>

<template>
    <DContainer
        type="footer"
        variant="block"
        class="d-site-footer"
        :data-test-id="dataTestId"
    >
        <DContainer class="d-site-footer-newsletter-container">
            <DText class="d-site-footer-newsletter-header" variant="h-2">
                Stay up to date
            </DText>
            <DText variant="p-3" color="muted" spacing="xl">
                Sign up to receive insider tips, new product and artist announcements,
                exclusive offers, and more.
            </DText>

            <DFromItem
                :id="newletterInput.id"
                :type="newletterInput.type"
                theme="dark"
                placeholder="Email Address"
                :data-test-id="`${dataTestId}-newsletter-subscriber`"
                @submit="$emit('subscribeToMailingList')"
            />
        </DContainer>

        <DContainer
            v-for="navSection in navigation"
            :key="navSection.title"
            type="ul"
            class="d-site-footer-navigation-list"
        >
            <DText class="d-site-footer-navigation-list-head" variant="l-1">
                {{navSection.title}}
            </DText>
            <DContainer
                v-for="link in navSection.subNavItems"
                :key="link.title"
                type="li"
                class="d-site-footer-navigation-list-item"
            >
                <DLink
                    :to="link.slug"
                    :href="link.externalLink"
                    class="d-site-footer-navigation-list-item-link"
                >
                    <DText variant="p-3" color="muted">
                        {{link.title}}
                    </DText>
                </DLink>
            </DContainer>
        </DContainer>

        <DContainer class="d-site-footer-social">
            <DText
                class="d-site-footer-copywrite"
                variant="p-3"
            >
                © {{currentYear}} Songfinch
            </DText>
            <template v-for="socialItem in navigationSocial" :key="socialItem.title">
                <DLink
                    class="d-site-footer-social-link"
                    :href="socialItem.externalLink"
                    :aria-label="socialItem.title"
                >
                    <DIcon v-if="socialItem.icon" :name="socialItem.icon"/>
                </DLink>
            </template>
        </DContainer>
    </DContainer>
</template>

<style lang="scss" scoped>
.d-site-footer {
  display: grid;
  background-color: var(--deep-black);
  color: var(--white);
  row-gap: 40px;
  grid-template-columns: 1fr 1fr;

  @include lg {
    grid-gap: 20px;
    /* Adds up to 1200px with grid gaps */
    grid-template-columns:
      minmax(auto, 606px)
      repeat(3, minmax(auto, 258px));
    row-gap: 96px;
  }
}

.d-site-footer-newsletter-container {
  max-width: 374px;
  margin-right: auto;
  grid-column: span 2;

  @include lg {
    grid-column: span 1;
  }
}

.d-site-footer-newsletter-header {
  margin-bottom: 12px;
}

.d-site-footer-navigation-list-head {
  margin-bottom: 21px;
}

.d-site-footer-navigation-list-item {
  margin-bottom: 18px;
}

.d-site-footer-social {
  display: flex;

  grid-column: span 2;

  @include lg {
    grid-column: span 4;
  }
}

.d-site-footer-copywrite {
  margin-right: auto;

  @include lg {
    margin-right: initial;
  }
}

.d-site-footer-social-link {
  font-size: 24px;
  margin-left: 21px;
}
</style>
