export default {
    pronunciation: {
        bannerTitle: "Say that again?",
        bannerMessage: "If you mentioned any people, places or things that might be tricky to pronounce, help out your artist by adding the pronunciation below",
    },
    errors: {
        agMissingCard: "Please personalize your card.",
        agMissingKey: "Missing AG ecard key. Please contact us at support@songfinch.com"
    },
    buttons: {
        agResetButton: "delete and start over"
    },
    bsSongArtistsList: {
        buttons: {
            moreArtists: "View more artists",
            pickForMe: "Pick for me! I can't decide",
            changeArtist: "change artist"
        }
    }
};
