import {watchEffect} from "vue";
import router from "@songfinch/customer/router";
import useDoWhileWithDelay from "@songfinch/shared/composables/useDoWhileWithDelay";
import onBrowserInteraction from "@songfinch/customer/composables/onBrowserInteraction";
import flipper from "@songfinch/shared/plugins/flipper";

declare global {
    interface Window {
        zE: unknown;
    }
}

declare module "vue-router" {
    interface RouteMeta {
        disableZenDesk?: boolean;
    }
}

if (!flipper.disable_zendesk) {
    onBrowserInteraction(() => {
        const js = document.createElement("script");
        js.id = "ze-snippet";
        js.src = "https://static.zdassets.com/ekr/snippet.js?key=6f3774bc-7fec-43af-9027-d9d2c210697c";
        document.body.appendChild(js);

        let widgetName;
        js.onload = () => useDoWhileWithDelay(
            () => {
                if (window.zE.widget) {
                    widgetName = window.zE.widget === "messenger" ? "messenger" : "webWidget";
                    watchEffect(() => window.zE?.(widgetName, !router.currentRoute?.value.meta?.disableZenDesk ? "show" : "hide"));
                }
            },
            () => !widgetName,
            1000,
            10
        );
    });
}

