import {computed} from "vue";
import {StorageSerializers, useStorage} from "@vueuse/core";
import {find} from "lodash-es";

interface NameElement {
    name_key: string;
    spelling: string;
    audio_path: string;
    active?: boolean;
    customSpelling?: string;
}

const selectedName = useStorage<NameElement[] | null>("sf_selected_name", null, undefined, {serializer: StorageSerializers.object});

export function setCurentName(val: NameElement[]) {
    selectedName.value = val;
}

export function resetNamesData() {
    selectedName.value = null;
}


//##########################################
export default function useInstantNames() {
    return {
        selectedName: computed(() => selectedName.value),
        selectedNameKey: computed(() => find(selectedName.value, {active: true})?.name_key),
    };
}
