import {App, Plugin} from "vue";

interface FlagsPluginInterface {
    set(key: string, value: boolean): void
    get(key: string): boolean
}

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $flags: FlagsPluginInterface
    }
}

const FlagsPlugin: Plugin = {
    install(app: App) {
        app.config.globalProperties.$flags = {
            set(key, value) {
                const flags = JSON.parse(localStorage.getItem("sf_flags")) || {};
                flags[key] = +value;
                localStorage.setItem("sf_flags", JSON.stringify(flags));
            },
            get(key) {
                const flags = JSON.parse(localStorage.getItem("sf_flags")) || {};
                return !!flags[key];
            }
        };
    }
};

export default FlagsPlugin;
