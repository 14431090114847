import axios from "axios";
import router from "../router";

const submission_id = localStorage.getItem("sf_submission_id");

if (submission_id) {
    axios.post("checkout/confirm_order", {submission_id, check_processed: true})
        .then(res => {
            if (res.data.is_processed) {
                router.push({name: "CompleteCheckout"});
            }
        });
}
