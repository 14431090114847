export default {
    start_your_song: "Start your song",
    continue_your_song: "Continue your song",
    occasion: "Occasion",
    genre: "Genre",
    vibe: "Vibe",
    tempo: "Tempo",
    edit: "Edit",
    add: "Add",
    learn_more: "Learn more",
    buy_now_song: "buy now",
    get_started: "Get started"
};
