export default (hash, delay = 100) => {
    if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
            setTimeout(() => {
                element?.scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                });
            }, delay);
            return true;
        }
    }
    return false;
};
