<template>
    <DContainer class="d-input-wrapper" :class="`${shade}-shade ${variant}`">
        <DContainer class="d-input-label-wrapper" :theme="theme">
            <DText
                class="d-input-label"
                type="label"
                variant="form-label"
                :for="formId"
            >
                {{label}}
            </DText>
        </DContainer>

        <input
            v-bind="$attrs"
            :id="formId"
            v-model="model"
            class="d-input"
            :name="formId"
            :type="type"
            :placeholder="placeholder"
        >

        <DIcon v-if="hasError" class="d-input-error-icon" name="alert"/>
    </DContainer>
</template>

<script lang="ts" setup>
    import {computed} from "vue";

    import DText from "../atoms/DText.vue";
    import DContainer from "../atoms/DContainer.vue";
    import DIcon from "../atoms/DIcon.vue";

    import {getComponentShade} from "../utils/theme";

    import type {BaseFormProps} from "@songfinch/types";

    const props = withDefaults(defineProps<BaseFormProps & {
        variant?: "with-icon";
    }>(), {
        theme: "light",
    });

    const shade = computed(() => getComponentShade(props.theme, props.hasError));

    const formId = "form-input-" + props.id;
    const model = defineModel<string>();
</script>
