const Songs = () => import("@songfinch/customer/views/songs/Songs.vue");
const PlaylistSingle = () => import("@songfinch/customer/views/songs/PlaylistSingle");

export default [
    {
        path: "/playlists",
        name: "Playlists",
        component: Songs,
    },
    {
        path: "/playlists/:id",
        name: "PlaylistSingle",
        meta: {customPageViewedAnalyticsTrigger: true},
        component: PlaylistSingle,
    }
];
