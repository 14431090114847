import {omit, pickBy, isEmpty, size} from "lodash-es";
import router from "../router";

export const apply_query_filters = (filters) => {
    const params = {...router.currentRoute.value.query};

    let filtersParams = omit(filters, ["per_page", "show_at_capacity"]);
    filtersParams = pickBy(filtersParams, p => !isEmpty(p));
    if (size(filtersParams)) {
        params.filters = encodeURIComponent(JSON.stringify(filtersParams));
    } else {
        delete params.filters;
    }

    let queryString = "";
    if (size(params)) {
        queryString += "?" + Object.keys(params).map(key => key + "=" + params[key]).join("&");
    }

    history.replaceState(history.state, null, router.currentRoute.value.path + queryString);
};

export const get_query_filters = () => {
    const queryFilters = router.currentRoute.value.query.filters;
    try {
        return queryFilters ? JSON.parse(queryFilters) : null;
    } catch (e) {
        return null;
    }
};
