let fancyObject;

const fancyboxDirective = {
    mounted(el, binding) {
        if (!fancyObject)
            el.addEventListener("click", (event) => clickFunction(event, binding.value));
        el.dataset.fancybox = binding.value;
    }
};

async function clickFunction(event) {
    const element = event.currentTarget;

    if (fancyObject) return;

    event.preventDefault();

    const imports = [
        import("@fancyapps/ui/src/Fancybox/Fancybox.js"),
        import("./fancybox.css")
    ];

    const [{Fancybox}] = await Promise.all(imports);

    fancyObject = Fancybox;

    Fancybox.bind(".splide__slide:not(.splide__slide--clone) .fancybox", {
        groupAll: true,
        Toolbar: {
            display: [
                {id: "prev", position: "center"},
                {id: "counter", position: "center"},
                {id: "next", position: "center"},
                "close",
            ],
        },
        Thumbs: {
            autoStart: true,
        }
    });

    element.click();
}

export default fancyboxDirective;
