import axios from "axios";
import type {StoryDataType, UpdateStorySubmissionType} from "./stories";


export const fetchStoryData = async (slug: string): Promise<StoryDataType> => {
    const res = await axios.get(`/stories/${slug}`);
    return res.data.data;
};

export const updateStorySubmission = async (slug: string, updateStorySubmission: UpdateStorySubmissionType) => {
    const res = await axios.put(`/stories/${slug}`, updateStorySubmission);
    return res.data;
};