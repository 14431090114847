export function setSessionUser(userData: unknown) {
    if (userData) {
        sessionStorage.setItem("sf_temp_user", JSON.stringify({sessioned: true, ...userData}));
    }
}

export function getSessionUser() {
    const saved = sessionStorage.getItem("sf_temp_user");
    if (saved) return JSON.parse(saved);
}
