import store from "../store";
import Cookies from "js-cookie";

const dataVersion = localStorage.getItem("sf_fe_data_version");

const pack = Cookies.get("sf_pack");

if (!pack || pack !== "customer") {
    //If we have any old data saved clear it
    if (dataVersion) {
        localStorage.clear();
    }
    Cookies.set("sf_pack", "customer", {expires: 100000});
}

if (dataVersion && dataVersion !== window.appSettings.data_version) {
    store.commit("shared/clearLocalStorage");
    location.reload();
    throw new Error("Ignore: Needs to reload");
} else if (!dataVersion) {
    localStorage.setItem("sf_fe_data_version", window.appSettings.data_version);
}
