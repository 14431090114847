import store from "@songfinch/customer/store";
import axios from "axios";

// const Home = () => import("@songfinch/customer/views/pages/Home.vue");
const Home2024 = () => import("@songfinch/customer/views/home/Home2024.vue");
const ArtistCommunity = () => import("@songfinch/customer/views/pages/ArtistCommunity.vue");
const SongRevisions = () => import("@songfinch/customer/views/pages/SongRevisions.vue");
const PrivacyPolicy = () => import("@songfinch/customer/views/pages/PrivacyPolicy.vue");
const Terms = () => import("@songfinch/customer/views/templates/Terms.vue");
const EventReminder = () => import("@songfinch/customer/views/pages/EventReminder.vue");
const Careers = () => import("@songfinch/customer/views/pages/Careers.vue");
const ContactUs = () => import("@songfinch/customer/views/pages/ContactUs.vue");
// const WorryFree = () => import("@songfinch/customer/views/pages/WorryFree.vue");
const HowItWorks = () => import("@songfinch/customer/views/pages/HowItWorks.vue");
const ExpiredOneTimeLink = () => import("@songfinch/customer/views/pages/ExpiredOneTimeLink.vue");

export default [
    {
        path: "/",
        name: "Home",
        component: Home2024,
        meta: {
            CMSData: "homepage2024",
            removeClearfix: true,
            baseTheme: "dark",
        },
    },
    {
        path: "/artist-community",
        name: "ArtistCommunity",
        component: ArtistCommunity,
    },
    {
        path: "/song-revisions",
        name: "SongRevisions",
        component: SongRevisions,
    },
    {
        path: "/privacy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/terms",
        name: "Terms",
        component: Terms,
    },
    {
        path: "/event-reminder",
        name: "EventReminder",
        component: EventReminder,
    },
    {
        path: "/careers",
        name: "Careers",
        component: Careers,
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/how-it-works",
        name: "HowItWorks",
        component: HowItWorks,
    },
    // {
    //     path: "/worry-free-guarantee",
    //     name: "WorryFree",
    //     component: WorryFree,
    // },
    {
        path: "/one-time-login",
        name: "OneTimeLogin",
        component: ExpiredOneTimeLink,
        beforeEnter: async (to) => {
            const {token, sign_in_origin} = to.query;

            try {
                // Redeem access token via API
                await axios.get("access_tokens", {
                    params: {token},
                });
                // Fetch logged-in user data
                await store.dispatch("auth/getUserData");

                // Handle redirect based on sign-in origin
                if (sign_in_origin === "checkout") {
                    return {name: "Checkout"};
                }
                return {name: "OrderHistory"};
            } catch (e) {
                // Fall back to expired link page in case of error
            }
        },
    },
];
