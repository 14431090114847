<template>
    <DContainer class="d-form-item">
        <component
            :is="resolveFormItem(type)"
            v-if="type"
            v-bind="{...computedProps, ...$attrs}"
            v-model="model"
            :has-error="hasError"
            @blur="handleBlur"
        />
        <DText v-if="hasError" class="d-form-item-error" variant="error">
            {{errorMessage}}
        </DText>
    </DContainer>
</template>

<script lang="ts" setup>
    import {computed, toRef} from "vue";
    import {getFormInputStaticOptions} from "../assets/staticOptions";

    import {useFormItemRender} from "../composables/forms/useFormItemRender";
    import {useFieldValidator} from "../composables/forms/useFieldValidator";

    import DContainer from "../atoms/DContainer.vue";
    import DText from "../atoms/DText.vue";

    import type {FormItemPropType} from "@songfinch/types";

    const {resolveFormItem} = useFormItemRender();

    const props = defineProps<FormItemPropType>();

    const computedProps = computed(() => {
        const computedPropsObject = {...props};
        if (props.staticOptionsId) {
            const options = getFormInputStaticOptions(props.staticOptionsId);
            computedPropsObject.options = options;
        }
        return computedPropsObject;
    });

    const {model, hasError, errorMessage, handleBlur} = useFieldValidator(
        toRef(props, "id"),
    );
</script>

<style lang="scss">
.d-form-item {
  position: relative;
  width: 100%;
  display: grid;
  justify-items: flex-start;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.d-form-item-error {
  position: absolute;
  top: 3.625rem;
  width: calc(100% - 2rem);
  margin-inline: 1rem;
}
</style>
