import {useScriptTag} from "@vueuse/core";

const loadedScripts: Record<string, boolean> = {};

export default async function useLoadScript(url: string) {
    if (loadedScripts[url]) return;
    const {load} = useScriptTag(url);
    await load();
    loadedScripts[url] = true;
    return;
}
