import {string, object, type StringSchema} from "yup";

export const validationObject = <T extends Record<string, StringSchema>>(
    params: T,
) => object(params);

// Input Validators
export const inputRequired = string().required("This field is required.");

// Email Validators
export const emailRequired = string()
    .required("Email address is required.")
    .email("Invalid email.");

export const validEmail = string().email("Invalid email.");

// Password Validators
export const passwordRequired = string().required("Password is required.");

// Phone Validators
export const validPhone = string().matches(
    /^\+?[1-9]\d{1,14}$/,
    "Invalid phone number format.",
);

export const phoneRequired = string()
    .required("Phone number is required.")
    .matches(/^\+?[1-9]\d{1,14}$/, "Invalid phone number format.");
