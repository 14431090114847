<script lang="ts" setup>
    import {computed} from "vue";
    import DLink from "../atoms/DLink.vue";
    import DTag from "../atoms/DTag.vue";
    import DText from "../atoms/DText.vue";
    import DContainer from "../atoms/DContainer.vue";
    import DIcon from "../atoms/DIcon.vue";

    import type {Navigation, NormalizedNavigationItem} from "@songfinch/types";
    import DButton from "../atoms/DButton.vue";
    import {ref} from "vue";

    const props = withDefaults(
        defineProps<{
            dataTestId: string;
            navigation: Navigation;
            accountNav: NormalizedNavigationItem;
            userAuthed: boolean;
        }>(),
        {
            dataTestId: "DMobileMenuDropDown",
        },
    );

    const emit = defineEmits(["close", "logoutUser", "login"]);

    const activeNavItem = ref();

    const toggleNavItem = (index: number) => {
        if (activeNavItem.value === index) {
            activeNavItem.value = null;
        } else {
            activeNavItem.value = index;
        }
    };

    const accountButtonLabel = computed(() => {
        return props.userAuthed ? "Log out" : "Sign in";
    });

    const handleAuthButtonClick = () => {
        props.userAuthed ? emit("logoutUser") : emit("login");
        emit("close");
    };
</script>

<template>
    <DContainer type="nav" class="d-site-navigation-mobile-wrapper" theme="dark">
        <DContainer class="d-site-navigation-mobile">
            <DButton
                class="d-site-navigation-mobile-close-dropdown"
                :data-test-id="`${dataTestId}-close`"
                icon="close"
                variant="icon"
                aria-label="menu"
                theme="dark"
                @click="emit('close')"
            />

            <DContainer type="ul" class="d-site-navigation-main-list-mobile">
                <DContainer
                    v-for="(item, index) in navigation"
                    :key="index"
                    type="li"
                    class="d-site-navigation-list-item-mobile"
                    :class="{
                        'd-has-submenu': item.subNavItems,
                        opened: activeNavItem === index,
                    }"
                    @click="item.subNavItems && toggleNavItem(index)"
                >
                    <template v-if="item.subNavItems">
                        <DContainer class="d-site-navigation-list-subitem-title-wrapper">
                            <DText variant="h-3">
                                {{item.title}}
                            </DText>
                            <DIcon
                                class="d-site-navigation-list-subitem-icon"
                                name="chevron_right"
                            />
                        </DContainer>
                        <DContainer class="d-site-navigation-list-mobile-wrapper">
                            <DContainer type="ul" class="d-site-navigation-list-mobile">
                                <DContainer
                                    v-for="subItem in item.subNavItems"
                                    :key="subItem.title"
                                    type="li"
                                    class="d-site-navigation-list-sub-menu"
                                >
                                    <DLink v-if="subItem.slug" :to="subItem.slug" @click="$emit('close')">
                                        <DText variant="p-4" v-html="subItem.title"/>
                                    </DLink>
                                    <DLink v-else-if="subItem.externalLink" :href="subItem.externalLink" :skipVueRouting="subItem.skipVueRouting">
                                        <DText variant="p-4" v-html="subItem.title"/>
                                    </DLink>
                                    <template v-else>
                                        <DText variant="p-4" v-html="subItem.title"/>
                                    </template>

                                    <DTag v-if="subItem.tag" :label="subItem.tag"/>
                                </DContainer>
                            </DContainer>
                        </DContainer>
                    </template>

                    <DLink v-else-if="item.slug" :to="item.slug" @click="$emit('close')">
                        <DText variant="h-3">
                            {{item.title}}
                        </DText>
                    </DLink>
                    <DLink v-else-if="item.externalLink" :to="item.externalLink">
                        <DText variant="h-3">
                            {{item.title}}
                        </DText>
                    </DLink>
                    <template v-else>
                        <DText variant="h-3">
                            {{item.title}}
                        </DText>
                    </template>
                </DContainer>

                <DContainer
                    v-if="userAuthed"
                    type="li"
                    class="d-site-navigation-list-item-mobile d-has-submenu"
                    :class="{opened: activeNavItem === -1}"
                    @click="toggleNavItem(-1)"
                >
                    <DContainer class="d-site-navigation-list-subitem-title-wrapper">
                        <DText variant="h-3">
                            Account
                        </DText>
                        <DIcon
                            class="d-site-navigation-list-subitem-icon"
                            name="chevron_right"
                        />
                    </DContainer>
                    <DContainer
                        v-if="accountNav.subNavItems"
                        class="d-site-navigation-list-mobile-wrapper"
                    >
                        <DContainer type="ul" class="d-site-navigation-list-mobile">
                            <DContainer
                                v-for="accItem in accountNav.subNavItems"
                                :key="accItem.title"
                                type="li"
                                class="d-site-navigation-list-sub-menu"
                            >
                                <DLink v-if="accItem.slug" :to="accItem.slug" @click="$emit('close')">
                                    <DText variant="p-4">
                                        {{accItem.title}}
                                    </DText>
                                </DLink>
                                <DLink v-else-if="accItem.externalLink" :href="accItem.externalLink" :skipVueRouting="accItem.skipVueRouting">
                                    <DText variant="p-4">
                                        {{accItem.title}}
                                    </DText>
                                </DLink>
                                <template v-else>
                                    <DText variant="p-4">
                                        {{accItem.title}}
                                    </DText>
                                </template>
                            </DContainer>
                        </DContainer>
                    </DContainer>
                </DContainer>
            </DContainer>


            <DButton
                v-if="userAuthed"
                class="d-site-navigation-cta-account"
                :label="accountButtonLabel"
                :data-test-id="`${dataTestId}-account`"
                variant="tertiary"
                size="large"
                to="#"
                @click="handleAuthButtonClick()"
            />

            <DButton
                v-else
                class="d-site-navigation-cta-account"
                :data-test-id="`${dataTestId}-login`"
                size="large"
                variant="tertiary"
                :to="'/login'"
                label="Sign in"
                @click="$emit('close')"
            />
        </DContainer>
    </DContainer>
</template>

<style lang="scss" scoped>
.d-site-navigation-mobile-wrapper {
  position: fixed;
  padding: var(--size-80) var(--size-32) var(--size-24);
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  overflow: hidden;
  transition: max-width 0.3s;
}

.d-site-navigation-mobile {
  display: grid;
  height: 100%;
}

.d-site-navigation-mobile-close-dropdown {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.d-site-navigation-cta-account {
  margin-top: auto;
}

.d-site-navigation-main-list-mobile {
  display: flex;
  flex-direction: column;
  gap: var(--size-24) 0;

  .d-site-navigation-list-item-mobile {
    &.d-has-submenu {
      .d-site-navigation-list-subitem-title-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;

        .d-site-navigation-list-subitem-icon {
          margin-left: var(--size-10);
          transform: rotate(90deg);
          transition: 0.5s;
        }
      }

      .d-site-navigation-list-sub-menu {
        display: flex;
        justify-content: space-between;
        padding-inline: var(--size-16);
        color: var(--white-tint-50);
      }

      .d-site-navigation-list-mobile-wrapper {
        display: grid;
        grid-template-rows: 0fr;
        transition: 0.5s ease-out;
      }

      .d-site-navigation-list-mobile {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: var(--size-18) 0;
      }

      &.opened {
        .d-site-navigation-list-subitem-title-wrapper {
          .d-site-navigation-list-subitem-icon {
            transform: rotate(90deg) scaleX(-1);
          }
        }

        .d-site-navigation-list-mobile-wrapper {
          grid-template-rows: 1fr;
          margin-top: var(--size-24);
          margin-bottom: var(--size-20);
        }
      }
    }
  }
}
</style>
