import {UIPlugin} from "@uppy/core";

interface ImageSizeValidatorOptions {
    id?: string;
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    onError?: (message: string) => void;
}

class ImageSizeValidator extends UIPlugin {
    opts: ImageSizeValidatorOptions;
    id: string;

    constructor(uppy, opts) {
        super(uppy, {...opts});
        this.opts = opts;
        this.id = this.opts.id || "ImageSizeValidator";
        this.type = "validator";
    }

    async validateImageSize(file) {
        if (!file.type || !file.type.startsWith("image/")) {
            return true;
        }

        const image = new Image();
        image.src = URL.createObjectURL(file.data);

        await new Promise((resolve) => (image.onload = resolve));

        const {width, height} = image;
        URL.revokeObjectURL(image.src);

        if (
            width < this.opts.minWidth ||
            height < this.opts.minHeight ||
            width > this.opts.maxWidth ||
            height > this.opts.maxHeight
        ) {
            const errorMessage = `Image dimensions should be between ${this.opts.minWidth}x${this.opts.minHeight} and ${this.opts.maxWidth}x${this.opts.maxHeight} pixels.`;
            if (this.opts.onError) this.opts.onError(errorMessage);
            else alert(errorMessage);
            return false;
        }

        return true;
    }

    install() {
        this.uppy.on("file-added", async (file) => {
            const isValid = await this.validateImageSize(file);

            if (!isValid) {
                this.uppy.setFileState(file.id, {
                    error: `Image dimensions do not meet the required size`,
                });
            }
        });
    }

    uninstall() {
        this.uppy.off("file-added", this.validateImageSize);
    }
}

export default ImageSizeValidator;
