export default {
    preview: {
        title: "song history",
        buttons: {
            generate: "New song",
            select: "Pick this song",
        },
        modal: {
            title: "Song history"
        }
    },
    loading: {
        firstLine: "Generating song preview...",
        secondLine: "This could take up to 30 seconds"
    },
    steps: {
        changeWarning: "Changing answers will overwrite this song’s lyrics.",
        notSelect: "Please make a selection.",
        pleaseSelectTwo: "Oops! Please select two."
    },
    names: {
        title: "Include their name",
        button: "Looks good",
        skipButton: "Skip and continue to song",
        confirmName: {
            editSpelling: "Edit spelling",
            findPronunciationButton: "Find pronunciation"
        },
        findPronunciationModal: {
            button: "This is how it sounds",
            skipButton: "None of these sound right"
        },
        multyModal: {
            button: "This is how it sounds",
            skipButton: "None of these sound right"
        },
        findModal: {
            AddPronunciation: "Add your pronunciation",
        },
        sorryModal: {
            button: "Sure!",
            skipButton: "Skip and continue to song"
        },
        newPronunciationModal: {
            button: "Submit",
            skipButton: "Skip and continue to song"
        }
    },
    emailCaptureModal: {
        title: "We’re mixing your song",
        subtitle: "Provide your email address to hear what our artists created for you. No quarters necessary!",
        button: "Preview your song options",
        label: "Email address"
    },
    confirm: {
        pickLabel: "Your pick",
        button: "Continue",
    },
    slideshow: {
        button: "Continue",
    },
    addons: {
        pageTitile: "Customize your song",
        buttons: {
            continue_to_cart: "Continue to cart",
            add: "add"
        }
    }
};
