<template>
    <a v-if="showStartSong" href="/song-builder" @click.prevent="clicked">
        {{text || $t(isStoryIncomplete ? "shared.continue_your_song" : "shared.start_your_song")}}
    </a>
</template>

<script>
    export default {
        name: "StartSongButton",
        props: {
            hideNoneActive: Boolean,
            text: {
                type: String,
                default: ""
            }
        },
        emits: ["click"],
        computed: {
            lastSongBuildPage() {
                return this.$store.state.songBuilder.songData.lastSongBuildPage;
            },
            isStoryIncomplete() {
                return this.lastSongBuildPage;
            },
            hasOriginalSong() {
                return this.$store.state.cart.cart.hasOriginalSong;
            },
            showStartSong() {
                return (!this.hideNoneActive || this.isStoryIncomplete) && !this.hasOriginalSong;
            },
        },
        methods: {
            clicked($event) {
                this.$emit("click", $event);
                this.$router.push({name: "SongBuilder"});
            }
        }
    };
</script>
