export default {
    remove_song_modal: {
        title: "Are you sure?",
        content: "Deleting your song will lose your memories and story details about {recipient}. Are you sure?",
        buttons: {
            cancel: "Cancel",
            remove: "I want to delete my song",
            new_artist: "Find a new artist"
        }
    }
};
