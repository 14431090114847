<template>
    <Component 
        :is="componentObject.component"
        v-if="componentObject" 
        v-bind="componentObject.props"
        tabindex="0" 
        class="d-link" 
    >
        <DIcon v-if="icon" :name="icon" :size="size"/>
        <slot/>
    </Component>
</template>

<script setup lang="ts">
    import {RouterLink, type RouteLocationRaw} from "vue-router";
    import DIcon from "./DIcon.vue";

    import type {IconNameTypes} from "@songfinch/types";
    import {computed} from "vue";

    // skipVueRouting Appears to be a hack to get around using Vue Router with multiple Apps Opting to use this here for now but long term this should probably be moved to be handled on the server.

    const props = withDefaults(
        defineProps<{
            to?: RouteLocationRaw;
            href?: string;
            icon?: IconNameTypes;
            skipVueRouting?: boolean;
            size?: "small" | "medium" | "large";
            variant?: "text" | "icon";
        }>(),
        {
            size: "medium",
        },
    );

    const componentObject = computed(() => {
        if (props.skipVueRouting || props.href) {
            return {
                component: "a",
                props: {
                    href: props.href,
                    class: `${props.variant} ${props.skipVueRouting ? "skipVueRouting" : ""}`,
                },
            };
        } else {
            return {
                component: RouterLink,
                props: {
                    to: props.to,
                    class: props.variant,
                },
            };
        }
    });
</script>

<style scoped lang="scss">
.text {
  text-decoration: underline;
}

.icon {
  width: 24px;
  height: 24px;
}

a {
    &:hover {
        color: inherit;
    }
}
</style>
