import {useStorage} from "@vueuse/core";
import router from "@songfinch/customer/router";
import {pick} from "lodash-es";

type LastReferralPageRoute = {
    name: string,
    query?: {[key: string]: string},
    params?: {[key: string]: string},
}

type LastReferralPageOrigin = {
    sfRedirectTo: string,
}

const lastReferralPage: LastReferralPageRoute | LastReferralPageOrigin = useStorage("sf_last_referral_page", {});

export function setLastReferralPage(to, from) {
    if (from.matched[0] && from.matched[0]?.name !== to.matched[0].name) {
        lastReferralPage.value = pick(from, ["name", "params", "query"]);
    } else if (to.query.sfRedirectTo) {
        lastReferralPage.value = {sfRedirectTo: to.query.sfRedirectTo};
    }
}

export function goToReferalPage () {
    if (lastReferralPage.value?.sfRedirectTo) {
        location.href = lastReferralPage.value.sfRedirectTo;
    } else {
        let page: LastReferralPageRoute;
        try {
            if (lastReferralPage.value?.name) {
                router.resolve(lastReferralPage.value);
                page = lastReferralPage.value;
            }
        } catch (e) {
            page = undefined;
        }
        if (!page) {
            const defaultRoutePage = router.currentRoute.value.matched[0].meta.defaultReferralPage;
            page = defaultRoutePage ? {name: defaultRoutePage} : {name: "Home"};
        }
        page.query = {...page.query, close_songbuilder: 1};
        router.push(page);
    }
}
