//Meta for all and beforeEnter only for chidless routes
const setMetaToAllChildren = (route, meta, beforeEnter = null, index = 0) => {
    if (!index) window.sfLastChild = null;
    route.children.forEach((child, i) => {
        child.meta = {...meta, ...child.meta, index: index++};
        if (child.children?.length) {
            index = setMetaToAllChildren(child, meta, beforeEnter, index);
        } else {
            if (beforeEnter) {
                if (!Array.isArray(child.beforeEnter)) {
                    child.beforeEnter = child.beforeEnter ? [child.beforeEnter] : [];
                }
                if (Array.isArray(beforeEnter)) {
                    child.beforeEnter = child.beforeEnter.concat(beforeEnter);
                } else {
                    child.beforeEnter.push(beforeEnter);
                }
            }
            child.meta.total_children = route.children.length;
            child.meta.child_index = i;
            if (window.sfLastChild) {
                child.meta.prevPage ??= window.sfLastChild.name;
                window.sfLastChild.meta.nextPage ??= child.name;
            }
            window.sfLastChild = child;
        }
    });
    if (!index) delete window.sfLastChild;
    return index;
};

function skipRouteToNext(to, from, skip) {
    if (skip) {
        const isPrevPage = (from.meta.index === undefined && to.index) || from.meta.index > to.meta.index;
        const nextPage = {name: isPrevPage ? to.meta.prevPage : to.meta.nextPage, params: to.params, query: to.query};
        return nextPage;
    }
}

export {setMetaToAllChildren, skipRouteToNext};
