import type {SVGAttributes} from "vue";
import type {IconNameTypes} from "@songfinch/types";

export interface SvgIconType {
  name: IconNameTypes;
  paths: SVGAttributes[];
}

const icons: SvgIconType[] = [
    {
        name: "add_plus",
        paths: [
            {
                d: "M6 12H12M12 12H18M12 12V18M12 12V6",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                stroke: "currentColor",
            },
        ],
    },
    {
        name: "alert",
        paths: [
            {
                d: "M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z",
                fill: "currentColor",
            },
            {
                d: "M12 8.4502V12.4502M12.0498 15.4502V15.5502L11.9502 15.5498V15.4502H12.0498Z",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                stroke: "white",
            },
        ],
    },
    {
        name: "arrow_left_md",
        paths: [
            {
                d: "M19 12H5M5 12L11 18M5 12L11 6",
                stroke: "currentColor",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "arrow_right_md",
        paths: [
            {
                d: "M5 12H19M19 12L13 6M19 12L13 18",
                stroke: "currentColor",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "calendar",
        paths: [
            {
                d: "M8 4H7.2002C6.08009 4 5.51962 4 5.0918 4.21799C4.71547 4.40973 4.40973 4.71547 4.21799 5.0918C4 5.51962 4 6.08009 4 7.2002V8M8 4H16M8 4V2M16 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V8M16 4V2M4 8V16.8002C4 17.9203 4 18.4801 4.21799 18.9079C4.40973 19.2842 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H16.8031C17.921 20 18.48 20 18.9074 19.7822C19.2837 19.5905 19.5905 19.2842 19.7822 18.9079C20 18.4805 20 17.9215 20 16.8036V8M4 8H20M16 16H16.002L16.002 16.002L16 16.002V16ZM12 16H12.002L12.002 16.002L12 16.002V16ZM8 16H8.002L8.00195 16.002L8 16.002V16ZM16.002 12V12.002L16 12.002V12H16.002ZM12 12H12.002L12.002 12.002L12 12.002V12ZM8 12H8.002L8.00195 12.002L8 12.002V12Z",
                stroke: "currentColor",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "camera",
        paths: [
            {
                d: "M9.48898 7H6.2002C5.08009 7 4.51962 7 4.0918 7.21799C3.71547 7.40973 3.40973 7.71547 3.21799 8.0918C3 8.51962 3 9.08009 3 10.2002V15.8002C3 16.9203 3 17.4796 3.21799 17.9074C3.40973 18.2837 3.71547 18.5905 4.0918 18.7822C4.5192 19 5.07899 19 6.19691 19H17.8031C18.921 19 19.48 19 19.9074 18.7822C20.2837 18.5905 20.5905 18.2837 20.7822 17.9074C21 17.48 21 16.921 21 15.8031V10.1969C21 9.07899 21 8.5192 20.7822 8.0918C20.5905 7.71547 20.2837 7.40973 19.9074 7.21799C19.4796 7 18.9203 7 17.8002 7H14.5108M9.48898 7H9.55078M9.48898 7C9.50151 7.00001 9.51468 7 9.52857 7L9.55078 7M9.48898 7C9.38286 6.99995 9.32339 6.99941 9.27637 6.99414C8.68878 6.92835 8.28578 6.36908 8.40918 5.79084C8.42066 5.73703 8.44336 5.66894 8.4883 5.53412L8.49023 5.52841C8.54156 5.37443 8.56723 5.29743 8.59558 5.22949C8.88586 4.53389 9.54322 4.06083 10.2949 4.00541C10.3683 4 10.449 4 10.6113 4H13.3886C13.5509 4 13.6322 4 13.7057 4.00541C14.4574 4.06083 15.114 4.53389 15.4043 5.22949C15.4326 5.29743 15.4584 5.37434 15.5098 5.52832C15.556 5.66699 15.5791 5.73636 15.5908 5.79093C15.7142 6.36917 15.3118 6.92835 14.7242 6.99414C14.6772 6.99941 14.6171 6.99995 14.5108 7M9.55078 7H14.449M14.449 7H14.5108M14.449 7L14.4712 7C14.4851 7 14.4983 7.00001 14.5108 7M12 16C10.3431 16 9 14.6569 9 13C9 11.3431 10.3431 10 12 10C13.6569 10 15 11.3431 15 13C15 14.6569 13.6569 16 12 16Z",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "cart",
        paths: [
            {
                d: "M 6.975586 15.75 C 7.347656 15.75 7.649414 15.448242 7.649414 15.076172 C 7.649414 14.701172 7.347656 14.399414 6.975586 14.399414 C 6.603516 14.399414 6.298828 14.701172 6.298828 15.076172 C 6.298828 15.448242 6.603516 15.75 6.975586 15.75 Z M 6.975586 15.75",
                stroke: "currentColor",
                fill: "currentColor",
                transform: "matrix(1.333333,0,0,1.333333,0,0)",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "1.8",
                "fill-opacity": "1",
                "fill-rule": "nonzero",
                "stroke-miterlimit": "4",
            },
            {
                d: "M 14.399414 15.75 C 14.771484 15.75 15.076172 15.448242 15.076172 15.076172 C 15.076172 14.701172 14.771484 14.399414 14.399414 14.399414 C 14.027344 14.399414 13.725586 14.701172 13.725586 15.076172 C 13.725586 15.448242 14.027344 15.75 14.399414 15.75 Z M 14.399414 15.75",
                stroke: "currentColor",
                fill: "currentColor",
                transform: "matrix(1.333333,0,0,1.333333,0,0)",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "1.8",
                "fill-opacity": "1",
                "fill-rule": "nonzero",
                "stroke-miterlimit": "4",
            },
            {
                d: "M 1.576172 1.576172 L 4.274414 1.576172 L 6.084961 10.614258 C 6.146484 10.924805 6.313477 11.203125 6.5625 11.402344 C 6.808594 11.601562 7.116211 11.707031 7.432617 11.701172 L 13.995117 11.701172 C 14.311523 11.707031 14.619141 11.601562 14.868164 11.402344 C 15.114258 11.203125 15.28418 10.924805 15.345703 10.614258 L 16.423828 4.951172 L 4.951172 4.951172",
                transform: "matrix(1.333333,0,0,1.333333,0,0)",
                fill: "none",
                stroke: "currentColor",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "1.8",
                "fill-opacity": "1",
                "stroke-miterlimit": "4",
            },
        ],
    },
    {
        name: "chat",
        paths: [
            {
                d: "M5.59961 19.9203L7.12357 18.7012L7.13478 18.6926C7.45249 18.4384 7.61281 18.3101 7.79168 18.2188C7.95216 18.1368 8.12328 18.0771 8.2998 18.0408C8.49877 18 8.70603 18 9.12207 18H17.8031C18.921 18 19.4806 18 19.908 17.7822C20.2843 17.5905 20.5905 17.2842 20.7822 16.9079C21 16.4805 21 15.9215 21 14.8036V7.19691C21 6.07899 21 5.5192 20.7822 5.0918C20.5905 4.71547 20.2837 4.40973 19.9074 4.21799C19.4796 4 18.9203 4 17.8002 4H6.2002C5.08009 4 4.51962 4 4.0918 4.21799C3.71547 4.40973 3.40973 4.71547 3.21799 5.0918C3 5.51962 3 6.08009 3 7.2002V18.6712C3 19.7369 3 20.2696 3.21846 20.5433C3.40845 20.7813 3.69644 20.9198 4.00098 20.9195C4.35115 20.9191 4.76744 20.5861 5.59961 19.9203Z",
                stroke: "currentColor",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "chevron_left",
        paths: [
            {
                d: "M13 18L7 12L13 6",
                stroke: "currentColor",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "chevron_right",
        paths: [
            {
                d: "M9 5L16 12L9 19",
                stroke: "currentColor",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "circle_check",
        paths: [
            {
                d: "M15 10L11 14L9 12M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "close",
        paths: [
            {
                d: "m17 17-5-5m0 0L7 7m5 5 5-5m-5 5-5 5",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "dropdown",
        paths: [
            {
                d: "M17 9.5L12 14.5L7 9.5",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "enter_arrow",
        paths: [
            {
                d: "M5.12891 12H18.3789M18.3789 12L12.3789 6M18.3789 12L12.3789 18",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
            },
        ],
    },
    {
        name: "facebook",
        paths: [
            {
                d: "M13.1524 21.8883C17.6936 21.1645 21.1752 17.013 21.1752 12C21.1752 6.47715 16.9494 2 11.7367 2C6.52392 2 2.29816 6.47715 2.29816 12C2.29816 17.013 5.77977 21.1645 10.3209 21.8883V15H8.90511C8.12319 15 7.48933 14.3284 7.48933 13.5C7.48933 12.6716 8.12319 12 8.90511 12H10.3209V10C10.3209 8.067 11.7999 6.5 13.6244 6.5H14.0963C14.8782 6.5 15.5121 7.17157 15.5121 8C15.5121 8.82843 14.8782 9.5 14.0963 9.5H13.6244C13.3638 9.5 13.1524 9.72386 13.1524 10V12H14.5682C15.3501 12 15.984 12.6716 15.984 13.5C15.984 14.3284 15.3501 15 14.5682 15H13.1524V21.8883Z",
                fill: "currentColor",
            },
        ],
    },
    {
        name: "hamburger",
        paths: [
            {
                d: "M5 17H19M5 12H19M5 7H19",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "heart",
        paths: [
            {
                d: "M12 7.69431C10 2.99988 3 3.49988 3 9.49991C3 15.4999 12 20.5001 12 20.5001C12 20.5001 21 15.4999 21 9.49991C21 3.49988 14 2.99988 12 7.69431Z",
                fill: "currentColor",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "heart_outline",
        paths: [
            {
                d: "M12 7.69431C10 2.99988 3 3.49988 3 9.49991C3 15.4999 12 20.5001 12 20.5001C12 20.5001 21 15.4999 21 9.49991C21 3.49988 14 2.99988 12 7.69431Z",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            },
        ],
    },
    {
        name: "instagram",
        paths: [
            {
                d: "M15.9907 3C18.7521 3 20.9907 5.23858 20.9907 8V16C20.9907 18.7614 18.7521 21 15.9907 21H7.99066C5.22924 21 2.99066 18.7614 2.99066 16V8C2.99066 5.23858 5.22924 3 7.99066 3H15.9907ZM11.9907 8C9.78152 8 7.99066 9.79086 7.99066 12C7.99066 14.2091 9.78152 16 11.9907 16C14.1998 16 15.9907 14.2091 15.9907 12C15.9907 9.79086 14.1998 8 11.9907 8ZM11.9907 10C13.0953 10 13.9907 10.8954 13.9907 12C13.9907 13.1046 13.0953 14 11.9907 14C10.8861 14 9.99066 13.1046 9.99066 12C9.99066 10.8954 10.8861 10 11.9907 10ZM16.4907 6.5C15.9384 6.5 15.4907 6.94772 15.4907 7.5C15.4907 8.05228 15.9384 8.5 16.4907 8.5C17.043 8.5 17.4907 8.05228 17.4907 7.5C17.4907 6.94772 17.043 6.5 16.4907 6.5Z",
                fill: "currentColor",
            },
        ],
    },
    {
        name: "pause",
        paths: [
            {
                d: "M15 5.5V18.5C15 18.9647 15 19.197 15.0384 19.3902C15.1962 20.1836 15.816 20.8041 16.6094 20.9619C16.8026 21.0003 17.0349 21.0003 17.4996 21.0003C17.9642 21.0003 18.1974 21.0003 18.3906 20.9619C19.184 20.8041 19.8041 20.1836 19.9619 19.3902C20 19.1987 20 18.9687 20 18.5122V5.48777C20 5.03125 20 4.80087 19.9619 4.60938C19.8041 3.81599 19.1836 3.19624 18.3902 3.03843C18.197 3 17.9647 3 17.5 3C17.0353 3 16.8026 3 16.6094 3.03843C15.816 3.19624 15.1962 3.81599 15.0384 4.60938C15 4.80257 15 5.03534 15 5.5Z",
                fill: "currentColor",
            },
            {
                d: "M4 5.5V18.5C4 18.9647 4 19.197 4.03843 19.3902C4.19624 20.1836 4.81599 20.8041 5.60938 20.9619C5.80257 21.0003 6.0349 21.0003 6.49956 21.0003C6.96421 21.0003 7.19743 21.0003 7.39062 20.9619C8.18401 20.8041 8.8041 20.1836 8.96191 19.3902C9 19.1987 9 18.9687 9 18.5122V5.48777C9 5.03125 9 4.80087 8.96191 4.60938C8.8041 3.81599 8.18356 3.19624 7.39018 3.03843C7.19698 3 6.96465 3 6.5 3C6.03535 3 5.80257 3 5.60938 3.03843C4.81599 3.19624 4.19624 3.81599 4.03843 4.60938C4 4.80257 4 5.03534 4 5.5Z",
                fill: "currentColor",
            },
        ],
    },
    {
        name: "play",
        paths: [
            {
                d: "M5 17.3336V6.66698C5 5.78742 5 5.34715 5.18509 5.08691C5.34664 4.85977 5.59564 4.71064 5.87207 4.67499C6.18868 4.63415 6.57701 4.84126 7.35254 5.25487L17.3525 10.5882L17.3562 10.5898C18.2132 11.0469 18.642 11.2756 18.7826 11.5803C18.9053 11.8462 18.9053 12.1531 18.7826 12.4189C18.6418 12.7241 18.212 12.9537 17.3525 13.4121L7.35254 18.7454C6.57645 19.1593 6.1888 19.3657 5.87207 19.3248C5.59564 19.2891 5.34664 19.1401 5.18509 18.9129C5 18.6527 5 18.2132 5 17.3336Z",
                fill: "currentColor",
            },
        ],
    },
    {
        name: "remove_minus",
        paths: [
            {
                d: "M6 12H18",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                stroke: "currentColor",
            },
        ],
    },
    {
        name: "share",
        paths: [
            {
                d: "M9 6L12 3M12 3L15 6M12 3V13M7.00023 10C6.06835 10 5.60241 10 5.23486 10.1522C4.74481 10.3552 4.35523 10.7448 4.15224 11.2349C4 11.6024 4 12.0681 4 13V17.8C4 18.9201 4 19.4798 4.21799 19.9076C4.40973 20.2839 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07899 21 7.19691 21H16.8036C17.9215 21 18.4805 21 18.9079 20.7822C19.2842 20.5905 19.5905 20.2839 19.7822 19.9076C20 19.4802 20 18.921 20 17.8031V13C20 12.0681 19.9999 11.6024 19.8477 11.2349C19.6447 10.7448 19.2554 10.3552 18.7654 10.1522C18.3978 10 17.9319 10 17 10",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                stroke: "currentColor",
            },
        ],
    },
    {
        name: "star",
        paths: [
            {
                d: "M2.33496 10.3368C2.02171 10.0471 2.19187 9.52339 2.61557 9.47316L8.61914 8.76107C8.79182 8.74059 8.94181 8.63215 9.01465 8.47425L11.5469 2.98446C11.7256 2.59703 12.2764 2.59695 12.4551 2.98439L14.9873 8.47413C15.0601 8.63204 15.2092 8.74077 15.3818 8.76124L21.3857 9.47316C21.8094 9.52339 21.9791 10.0472 21.6659 10.3369L17.2278 14.4419C17.1001 14.56 17.0433 14.7357 17.0771 14.9063L18.255 20.8359C18.3382 21.2544 17.8928 21.5787 17.5205 21.3703L12.2451 18.4166C12.0934 18.3317 11.9091 18.3321 11.7573 18.417L6.48144 21.3695C6.10913 21.5779 5.66294 21.2544 5.74609 20.8359L6.92414 14.9066C6.95803 14.7361 6.90134 14.5599 6.77367 14.4419L2.33496 10.3368Z",
                fill: "currentColor",
            },
        ],
    },
    {
        name: "spotify",
        paths: [
            {
                d: "M12.2099 2C6.99718 2 2.77142 6.47715 2.77142 12C2.77142 17.5228 6.99718 22 12.2099 22C17.4226 22 21.6484 17.5228 21.6484 12C21.6484 6.47715 17.4226 2 12.2099 2ZM7.74443 10.4402C10.8034 9.53558 13.8838 9.99147 16.5549 11.2137C17.0335 11.4326 17.589 11.1991 17.7956 10.6921C18.0023 10.1851 17.7819 9.59654 17.3034 9.37757C14.3114 8.00853 10.7849 7.4646 7.23692 8.51383C6.73484 8.66231 6.44143 9.21391 6.58158 9.74586C6.72172 10.2778 7.24234 10.5887 7.74443 10.4402ZM8.19364 13.3064C10.8257 12.5288 13.8534 13.0146 15.5363 14.1095C15.9806 14.3986 16.5618 14.2513 16.8346 13.7807C17.1074 13.31 16.9683 12.6942 16.5242 12.4052C14.3419 10.9854 10.7626 10.4712 7.68661 11.3799C7.18449 11.5283 6.89094 12.0798 7.03096 12.6118C7.17097 13.1438 7.69153 13.4548 8.19364 13.3064ZM8.6011 16.0604C9.66739 15.8006 10.8536 15.7971 11.8967 15.9686C12.9728 16.1455 13.7484 16.4833 14.0848 16.7745C14.4885 17.1239 15.0831 17.0604 15.4129 16.6327C15.7427 16.205 15.6827 15.575 15.279 15.2256C14.5593 14.6025 13.3909 14.1903 12.1861 13.9922C10.9482 13.7887 9.51634 13.7852 8.17806 14.1113C7.67004 14.235 7.35292 14.7717 7.46973 15.3099C7.58655 15.8482 8.09308 16.1842 8.6011 16.0604Z",
                fill: "currentColor",
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
            },
        ],
    },
    {
        name: "tiktok",
        paths: [
            {
                d: "M14.1511 2C15.1937 2 16.0388 2.89543 16.0388 4C16.0388 5.44733 17.011 6.6618 18.3025 6.93991C19.3241 7.15992 19.984 8.21575 19.7763 9.29819C19.5687 10.3806 18.5722 11.0798 17.5505 10.8598C17.021 10.7457 16.5142 10.5646 16.0388 10.3252V16C16.0388 19.3137 13.5033 22 10.3757 22C7.24804 22 4.71259 19.3137 4.71259 16C4.71259 13.2015 6.51918 10.8551 8.96131 10.1891C9.97095 9.91382 11.0001 10.5578 11.26 11.6275C11.5198 12.6972 10.912 13.7876 9.90237 14.0629C9.08778 14.285 8.48799 15.0708 8.48799 16C8.48799 17.1046 9.33314 18 10.3757 18C11.4183 18 12.2634 17.1046 12.2634 16V4C12.2634 2.89543 13.1085 2 14.1511 2Z",
                fill: "currentColor",
            },
        ],
    },
    {
        name: "twitter",
        paths: [
            {
                d: "M13.9761 10.1624L22.7186 0H20.6469L13.0558 8.82384L6.99289 0H0L9.16836 13.3432L0 24H2.07179L10.0881 14.6817L16.491 24H23.4839L13.9756 10.1624H13.9761ZM11.1385 13.4608L10.2096 12.1321L2.81829 1.55962H6.00044L11.9653 10.0919L12.8942 11.4206L20.6479 22.5113H17.4657L11.1385 13.4613V13.4608Z",
                fill: "currentColor",
            },
        ],
    },
    {
        name: "user",
        paths: [
            {
                d: "M 14.399414 14.850586 L 14.399414 13.5 C 14.399414 12.785156 14.115234 12.09668 13.608398 11.589844 C 13.101562 11.083008 12.416016 10.798828 11.701172 10.798828 L 6.298828 10.798828 C 5.583984 10.798828 4.898438 11.083008 4.391602 11.589844 C 3.884766 12.09668 3.600586 12.785156 3.600586 13.5 L 3.600586 14.850586",
                transform: "matrix(1.333333,0,0,1.333333,0,0)",
                fill: "none",
                stroke: "currentColor",
                "stroke-width": "1.8",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-opacity": "1",
                "stroke-miterlimit": "4",
            },
            {
                d: "M 9 8.100586 C 10.491211 8.100586 11.701172 6.890625 11.701172 5.399414 C 11.701172 3.908203 10.491211 2.701172 9 2.701172 C 7.508789 2.701172 6.298828 3.908203 6.298828 5.399414 C 6.298828 6.890625 7.508789 8.100586 9 8.100586 Z M 9 8.100586",
                transform: "matrix(1.333333,0,0,1.333333,0,0)",
                fill: "none",
                stroke: "currentColor",
                "stroke-width": "1.8",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-opacity": "1",
                "stroke-miterlimit": "4",
            },
        ],
    },
    {
        name: "website",
        paths: [
            {
                d: "M18.2901 4C19.3327 4 20.1778 4.89543 20.1778 6V18C20.1778 19.1046 19.3327 20 18.2901 20H5.07624C4.03369 20 3.18854 19.1046 3.18854 18V6C3.18854 4.89543 4.03369 4 5.07624 4H18.2901ZM18.2901 10H5.07624V17C5.07624 17.5128 5.44061 17.9355 5.91002 17.9933L6.02009 18H17.3463C17.8303 18 18.2293 17.614 18.2838 17.1166L18.2901 17V10ZM6.02009 6C5.49882 6 5.07624 6.44772 5.07624 7C5.07624 7.55228 5.49882 8 6.02009 8C6.54136 8 6.96394 7.55228 6.96394 7C6.96394 6.44772 6.54136 6 6.02009 6ZM8.85164 6C8.33037 6 7.90779 6.44772 7.90779 7C7.90779 7.55228 8.33037 8 8.85164 8C9.37291 8 9.79549 7.55228 9.79549 7C9.79549 6.44772 9.37291 6 8.85164 6ZM11.6832 6C11.1619 6 10.7393 6.44772 10.7393 7C10.7393 7.55228 11.1619 8 11.6832 8C12.2045 8 12.627 7.55228 12.627 7C12.627 6.44772 12.2045 6 11.6832 6Z",
                fill: "currentColor",
            },
        ],
    },
];

export default icons;
