import {forEach} from "lodash-es";
import store from "../store";
const sb = store.state.songBuilder;
const dbVals = window.appSettings.events_value;

const gtm_song_data_helper = {
    BsOccasionRecipient: () => {
        if (!sb.songData.recipient || !sb.songData.relationship) return;
        sb.gtmSongData.recipient = sb.songData.recipient;
        sb.gtmSongData.relationship = sb.songData.relationship;
        sb.gtmSongData.mention_recipient = sb.songData.mention_recipient;

        sb.gtmSongData.occasion = sb.songData.occasion;
        sb.gtmSongData.mention_occasion = sb.songData.mention_occasion;
        sb.gtmSongData.step_name = "BsOccasionRecipient";
        sb.gtmSongData.step_number = 1;
    },
    BsSongArtist: () => {
        if (sb.songData.preferred_artist_switch === undefined) return;
        sb.gtmSongData.preferred_artist_switch = sb.songData.preferred_artist_switch;
        sb.gtmSongData.step_name = "BsSongArtist";
        sb.gtmSongData.step_number = 5;
    },
    BsSongCharacteristics: () => {
        if (!sb.songData.gender || !sb.songData.genre || !sb.songData.moods?.length || !sb.songData.tempo) return;
        sb.gtmSongData.gender = dbVals.genders[sb.songData.gender];
        sb.gtmSongData.genre = dbVals.genres[sb.songData.genre];
        sb.gtmSongData.moods = sb.songData?.moods.map(m => dbVals.moods[m]).join(", ") ;
        sb.gtmSongData.tempo = dbVals.tempos[sb.songData.tempo];
        sb.gtmSongData.step_name = "BsSongCharacteristics";
        sb.gtmSongData.step_number = 6;
    },
    BsSongArtistsList: () => {
        if (!sb.songData.selected_recommended_artist) return;
        sb.gtmSongData.recommended_artist_name = sb.songData.selected_recommended_artist.artist_name;
        sb.gtmSongData.recommended_artist_id = sb.songData.selected_recommended_artist.id;
        sb.gtmSongData.recommended_artists = sb.songData.recommended_artists;

        sb.gtmSongData.step_name = "BsSongArtistsList";
        // This step is skipped in the selected artist flow
        sb.gtmSongData.step_number = 7;

        // TODO: Note this is for backwards compatibility, we are updating the field name for a misspelling and need to update GTM after this
        sb.gtmSongData.submited_artists = null;
        sb.gtmSongData.submitted_artists = null;
    },
    BsSongArtistSelection: () => {
        if (!sb.songData.selected_artists?.length) return;
        // TODO: Note this is for backwards compatibility, we are updating the field name for a misspelling and need to update GTM after this
        sb.gtmSongData.submited_artists = sb.songData.selected_artists;
        sb.gtmSongData.submitted_artists = sb.gtmSongData.submited_artists;
        sb.gtmSongData.step_name = "BsSongArtistSelection";
        sb.gtmSongData.step_number = 5.1;

        sb.gtmSongData.recommended_artist_name = null;
        sb.gtmSongData.recommended_artist_id = null;
        sb.gtmSongData.recommended_artists = null;
    },
    BsStoryDetailsCombined: () => {
        if (!sb.songData.questions?.[0] || !sb.songData.questions?.[1]) return;
        sb.gtmSongData.story_details = `**${sb.songData.questions[0].question}**\r\n${sb.songData.questions[0].answer}`;
        sb.gtmSongData.story_additional_details = `**${sb.songData.questions[1].question}**\r\n\r\n${sb.songData.questions[1].answer}`;

        if (sb.songData.must_have_questions) {
            const new_array = {};
            forEach(sb.songData.must_have_questions, (el, index) => {
                if (el.answer) {
                    new_array[`musthave_${index + 1}`] = `**${el.answer}** - ${el.description}`;
                }
            });
            sb.gtmSongData.gtm_m_h_questions = JSON.stringify(new_array);
        }
        sb.gtmSongData.step_name = "BsStoryDetails";
        sb.gtmSongData.step_number = 10;
    },
    BsReview: () => {
        if (!sb.songData.gifter_email ) return;
        sb.gtmSongData.gifter_email = sb.songData.gifter_email;
        sb.gtmSongData.step_name = "BsReview";
        sb.gtmSongData.step_number = 12;
    }
};

const initGtmData = () => {
    Object.values(gtm_song_data_helper).forEach(v => v());
};

export default gtm_song_data_helper;

export {initGtmData};
