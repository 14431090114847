<script lang="ts" setup>
    import {computed} from "vue";

    import axios from "axios";
    import error_handler from "@songfinch/shared/helpers/axios_error_handler";
    import analyticsEvent from "@songfinch/shared/helpers/analyticsEvent";

    import {normalizeSiteFooterNavigation} from "@songfinch/utils/src/normalizers";

    import type {Navigation} from "@songfinch/types";

    import {useStore} from "vuex";

    import DSiteFooter from "@songfinch/design-system/src/navigation/DSiteFooter.vue";

    import {emailRequired} from "@songfinch/utils/src/validators";
    import {useForm} from "vee-validate";
    import {useNotification} from "@songfinch/design-system/src/composables/useNotification";

    const store = useStore();
    const {setNotification} = useNotification();

    computed(() => store.state.cms.footer);

    const navigation = computed<Navigation>(() => {
        if (!store.state.cms.footer.menu) return [];
        return normalizeSiteFooterNavigation(store.state.cms.footer.menu);
    });

    type RawSocialLink = {
        name: string;
        link: string;
        icon: unknown;
    };

    const navigationSocial = computed<Navigation>(() => {
        const socialLinks: RawSocialLink[] = Object.values(store.state.cms.footer.social_links);
        if (!socialLinks) return [];

        return socialLinks.map((item) => ({
            title: item.name,
            externalLink: item.link,
            icon: item.name,
        }));
    });

    const {handleSubmit, resetForm} = useForm({
        validationSchema: {
            newsletter: emailRequired,
        },
    });

    const sendEmail = handleSubmit(async (values) => {
        try {
            await axios.post("/forms/signup", {email: values.newsletter});
            
            analyticsEvent("_sf_footer_conversion");
            store.commit("shared/setHasInteractedWithSongfinch");

            setNotification({
                type: "success",
                heading: "Thanks for subscribing!",
                text: "Check your inbox for a confirmation email.",
            });

            resetForm();
        } catch (e) {
            setNotification({
                type: "error",
                heading: "Oops!",
                text: error_handler(e).error,
            });
        }
    });
</script>

<template>
    <DSiteFooter 
        :navigation="navigation" 
        :navigationSocial="navigationSocial" 
        :newletterInput="{
            id: 'newsletter',
            type: 'inputWithSubmit',
            validation: emailRequired,
            placeholder: 'Email Address',
        }"
        @subscribe-to-mailing-list="sendEmail"
    />
</template>