import router from "@songfinch/customer/router";
import store from "@songfinch/customer/store";
import {goToReferalPage} from "@songfinch/customer/composables/useReferralPage";

export function getCmsData<T = unknown>(name?: string): T {
    const key = name || router.currentRoute.value.name as string;
    return store.state.cms.pageData?.[key] || {};
}

export function goToNextPage() {
    return router.push({name: router.currentRoute.value.meta.nextPage as string});
}

export function goToPrevPage() {
    if (router.currentRoute.value.meta.prevPage) {
        let prevName = router.currentRoute.value.meta.prevPage as string;
        if (prevName === "IpLoadingPreview") {
            prevName = router.resolve({name: prevName}).meta.prevPage as string;
        }
        return router.push({name: prevName});
    }
    return goToReferalPage();
}
