export const scrollLock = () => {
    if (!document?.body && document?.documentElement) return;
    document.body.classList.add("scroll-lock");
    document.documentElement.classList.add("scroll-lock");
};

export const scrollUnlock = () => {
    if (!document?.body) return;
    document.body.classList.remove("scroll-lock");
    document.documentElement.classList.remove("scroll-lock");
};
