export default {
    button: {
        lyrics: "Lyrics",
        song_details: "Song Details",
    },
    label: {
        basic_detail: "The basic details"
    },
    applauseArtistButton: {
        button: "Love this song"
    },
    applauseSlideModal: {
        header: "Share some love",
        subheader: "Did you love this song? Let the world know! Select as many compliments as you like from the sections below, write your own – or both!",
        button: {
            category: {
                lyrics: {
                    title: "Amazing lyrics",
                    compliments: {
                        rich_storytelling: "rich storytelling",
                        highly_detailed: "highly detailed",
                        catchy_chorus: "catchy chorus",
                        poetic: "poetic",
                        touching: "touching",
                        resonated_with_me: "resonated with me"
                    },
                },
                voice: {
                    title: "Love the vocals",
                    compliments: {
                        angelic: "angelic",
                        soulful: "soulful",
                        warm: "warm",
                        enchanting: "enchanting",
                        velvety: "velvety",
                        sick_flow: "sick flow",
                        heartfelt: "heartfelt",
                        effortless: "effortless",
                        captivating: "captivating",
                        cool_grit: "cool grit",
                        clear: "clear"
                    },
                },
                feels: {
                    title: "Captivating Vibe",
                    compliments: {
                        "soul-stirring": "soul-stirring",
                        slaps: "slaps",
                        powerful: "powerful",
                        heartwarming: "heartwarming",
                        intense: "intense",
                        fierce: "fierce",
                        touching: "touching",
                        just_wow: "just wow",
                        poignant: "poignant",
                        raw: "raw",
                        timeless: "timeless",
                        moving: "moving",
                        fire: "fire",
                    },
                },
                sound: {
                    title: "Great Sound",
                    compliments: {
                        dope_beat: "dope beat",
                        infectious_melody: "infectious melody",
                        "radio-ready": "radio-ready",
                        great_style: "great style",
                        catchy: "catchy",
                        nailed_the_mood: "nailed the mood",
                        great_energy: "great energy",
                        hauntingly_beautiful: "hauntingly beautiful",
                    }
                },
                delivery: {
                    title: "Vocal delivery",
                    compliments: {
                        sick_flow: "sick flow",
                        heartfelt: "heartfelt",
                        effortless: "effortless",
                        captivating: "captivating",
                        cool_grit: "cool grit",
                        clear: "clear",
                    }
                },
                makes_feel: {
                    title: "Makes me feel",
                    compliments: {
                        invigorated: "Invigorated",
                        serene: "Serene",
                        happy: "Happy",
                        heartbroken: "Heartbroken",
                        fired_up: "Fired-up",
                        introspective: "Introspective",
                        sentimental: "Sentimental",
                        immersed: "Immersed",
                        inspired: "Inspired",
                        vulnerable: "Vulnerable",
                        captivated: "Captivated"
                    }
                }
            }
        },
        label: {
            comment: "Leave a comment",
            email: "Email address",
            name: "Screen Name",
            tooltipName: "Will be shown next to your comment.",
            tooltipEmail: "Required for moderation."
        },

        input: {
            comment: {
                placeholder: "Share any specific thoughts and details you enjoyed about the song (optional)"
            },
        },
        form: {
            success: "Your applause has been sent!"
        },
        submitForm: "Send",
        closeSlide: "Cancel"
    },
    emailCapturePopup: {
        songPlayed: {
            header: "Love this custom song?",
            subheader: "We’d love to help you create one of your own!",
            button: {
                submit: "Learn more"
            }
        },
        freeSong: {
            header: "Songfinch VIP Exclusive: Free Custom Song Bundle",
            subheader: "We've got a winner! Here's your chance to win a free original song bundled with streaming and your very own framed poster.",
            button: {
                submit: "Sign me up"
            }
        }
    },
    topBarFreeSongContent: {
        details: "win an original song and more"
    },
    spotifyBanner: {
        purchased: {
            title: "Listen on spotify",
            sub_title: "Listen & share anytime, anywhere"
        },
        notPurchased: {
            title: "Stream it on spotify",
            sub_title: "Share this song with the world"
        }
    },
    tooltipLearn: {
        button_label: "Got it"
    },
    heardAbout: {
        mainSection: {
            submitMessage: "Thanks so much for your feedback!"
        },
        firstScreen: {
            header: "How’d you hear about Songfinch?",
            submitButton: "Submit"
        },
        secondscreen: {
            header: "How’d you hear about Songfinch?",
            subheader: "Based on your previous selections, please answer the following.",
            backButton: "Back",
            submitButton: "Submit"
        }
    },
    comments: {
        main: {
            title: "Comments (%{count})",
            button_more: "Load More",
        },
        single: {
            person: "You",
            edit_btn: "Edit your comment",
            delete_btn: "Delete",
            read_more_btn: "Read More",
        },
        edit_field: {
            title: "Editing your response",
            button: "Cancel",
        },
        sort: {
            title: "Sort by",
            asc: "Newest to Oldest",
            desc: "Oldest to Newest"
        }
    }
};
